import React from "react";
import ReactDOM from "react-dom/client";
import "@streets-heaver/shui2/SHUI2Styles.css";
import "@streets-heaver/core/streets-heaver-ui.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { CompucoreProvider } from "@streetsheaver/compucore";
import App from "./App";
import "./dayjsSetup";

const queryClient = new QueryClient();

export const msalInstance = new PublicClientApplication(msalConfig);

const root = document.getElementById("root");
if (!root) {
	throw new Error("root element not found");
}

msalInstance.initialize().then(() => {
	const accounts = msalInstance.getAllAccounts();
	if (!msalInstance.getActiveAccount() && accounts.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}

	msalInstance.addEventCallback((event) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			const payload = event.payload;
			const account = payload.account;
			msalInstance.setActiveAccount(account);
		}
	});

	ReactDOM.createRoot(root).render(
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<MsalProvider instance={msalInstance}>
					<CompucoreProvider tenantsApiUrl={import.meta.env.VITE_TENANTS_API_URL} appName="Compucare Clinician">
						<App />
					</CompucoreProvider>
				</MsalProvider>
			</QueryClientProvider>
		</React.StrictMode>,
	);
});
