import { useState, useRef } from "react";
import { Divider, SegmentedControl, TabList } from "@streets-heaver/shui2";
import { Name } from "../Sections/Name/Name";
import { Identity } from "../Sections/Identity/Identity";
import { Contact } from "../Sections/Contact/Contact";
import { Identifiers } from "../Sections/Identifiers/Identifiers";
import { NextOfKin } from "../Sections/NextOfKin/NextOfKin";
import { Shared } from "../Sections/Shared/Shared";
import { useFormContext } from "react-hook-form";
import classes from "./PatientForm.module.scss";
import { useScreenSizeClass, ScreenSize } from "@streets-heaver/core";

export const EditPatientFormContent = () => {
	const containerRef = useRef(null);
	const form = useFormContext();
	const [selected, setSelected] = useState();
	const automationId = "edit-patient";
	const width = useScreenSizeClass();

	const title = form.register("name.primary.title");
	const surname = form.register("name.primary.surname");
	const forename = form.register("name.primary.forename");

	const handleScroll = () => {
		const sections = containerRef.current.querySelectorAll("[data-section]");
		let groupArray = [];
		sections.forEach((section) => {
			groupArray.push({
				name: section.id,
				positionTop: section.getBoundingClientRect().top,
				positionBottom: section.getBoundingClientRect().bottom,
			});
		});

		function findIndex() {
			let smallestPositive = 0;
			for (let i = 0; i < groupArray.length; i++) {
				if (groupArray[i].positionTop < 0) {
					smallestPositive++;
				}
			}
			return groupArray[smallestPositive];
		}
		const one = findIndex().name;
		setSelected(one);
	};

	const scrollTab = (e) => {
		const section = document.getElementById(e);
		section.scrollIntoView();
	};

	const tabItems = [
		{ label: "Name", value: "name" },
		{ label: "Identity", value: "identity" },
		{ label: "Contact", value: "contact" },
		{ label: "Identifiers", value: "identifiers" },
		{ label: "Next of kin", value: "nextOfKin" },
		{ label: "Shared", value: "shared" },
	];

	const formSections = [
		{
			component: <Name title={title} forename={forename} surname={surname} automationId={automationId} />,
			section: "name",
		},
		{ component: <Identity automationId={automationId} />, section: "identity" },
		{ component: <Contact automationId={automationId} />, section: "contact" },
		{ component: <Identifiers automationId={automationId} />, section: "identifiers" },
		{ component: <NextOfKin automationId={automationId} />, section: "nextOfKin" },
		{ component: <Shared automationId={automationId} />, section: "shared" },
	];
	return (
		<div data-section id="heading" className={classes.form}>
			<div className={classes.controller}>
				{width < ScreenSize.TabletLandscape ? (
					<SegmentedControl
						type="pills"
						segments={tabItems}
						onChange={(e) => {
							scrollTab(e);
							setSelected(e);
						}}
						updateSelectedSegment={selected}
						selected={selected}
						setSelected={setSelected}
					/>
				) : (
					<TabList
						tabs={tabItems}
						direction="column"
						updateSelectedTab={selected}
						onChange={(e) => {
							scrollTab(e);
							setSelected(e);
						}}
						selected={selected}
						setSelected={setSelected}
					/>
				)}
			</div>
			<div className={classes.sections} ref={containerRef} onScroll={handleScroll}>
				{formSections.map((section, index) => {
					return (
						<div id={section.section} key={`patient-form-section-${section?.section}`} className={classes.section}>
							{section.component}
							{index < formSections.length - 1 && <Divider />}
						</div>
					);
				})}
			</div>
		</div>
	);
};
