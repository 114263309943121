import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import Routes from "./layouts/routes";
import { InteractionType } from "@azure/msal-browser";

export default function App() {
	useMsalAuthentication(InteractionType.Redirect);
	return (
		<>
			<AuthenticatedTemplate>
				<Routes />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<div>You are currently not signed in. Please sign in.</div>
			</UnauthenticatedTemplate>
		</>
	);
}
