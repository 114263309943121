import classes from "./PrintForm.module.scss";
import { FormField } from "../FormField/FormField";
import { SegmentedControl } from "@streets-heaver/shui2";
import { useNavigate } from "react-router";
import { Controller, useFormContext } from "react-hook-form";
import { FormDatebox } from "../FormDatebox/FormDatebox";
import { ReportTemplatesLookup, SiteLookup } from "../lookups";
import { useSessionStorage } from "@streetsheaver/compucore";

export const PrintForm = ({ type, automationId }) => {
	const [bookingType, setBookingType] = useSessionStorage("printform.bookingType", "clinic");
	const methods = useFormContext();
	const navigate = useNavigate();

	const tabs = [
		{ label: "Clinic lists", value: "clinic" },
		{ label: "Surgical lists", value: "surgical" },
	];

	return (
		<div className={classes.printForm}>
			<SegmentedControl
				type="switch"
				onChange={(e) => {
					navigate(`../print/${e}`, { replace: true });
					methods.setValue(
						"template",
						JSON.parse(sessionStorage.getItem(`printform.template.${e}`)) || {
							reportTemplateId: 0,
							name: "System Defined Template",
						},
					);

					setBookingType(e);
				}}
				defaultSegment={bookingType || "clinic"}
				segments={tabs}
			/>
			<div className={classes.dateSelection}>
				<FormField label="Date from">
					<Controller
						name={"startDate"}
						control={methods.control}
						render={({ field: { value, onChange, error } }) => (
							<FormDatebox
								value={value}
								onChange={onChange}
								isError={error}
								automationId={`${automationId}-date-from`}
							/>
						)}
					/>
				</FormField>
				<FormField label="Date to">
					<Controller
						name={"endDate"}
						control={methods.control}
						render={({ field: { value, onChange, error } }) => (
							<FormDatebox value={value} onChange={onChange} isError={error} automationId={`${automationId}-date-to`} />
						)}
					/>
				</FormField>
			</div>
			<SiteLookup isMandatory automationId={automationId} />
			<ReportTemplatesLookup isMandatory type={type} automationId={automationId} />
		</div>
	);
};
