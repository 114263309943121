import { ScreenSize } from "@streets-heaver/core";

export const getHasActionsBar = (pagepath, width) => {
	if (width < ScreenSize.TabletLandscape) return false;

	switch (pagepath[0]) {
		case "patient-area":
			if (pagepath[2] === "journal" && pagepath.length < 4) {
				return true;
			}
			return false;
		case "scheduler":
		case "mypatients":
		case "orders":
			return true;
		default:
			return false;
	}
};
