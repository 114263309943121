import { localToUTC } from "../../utils";

export const transformPrintData = (data) => ({
	reportTemplateId: data?.template?.reportTemplateId === 0 ? null : data?.template?.reportTemplateId,
	filters: {
		fromDate: localToUTC(data?.startDate),
		toDate: localToUTC(data?.endDate),
		siteIds: [data?.site?.siteId],
	},
});
