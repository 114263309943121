import {
	faBook,
	faCalendarDays,
	faClipboardList,
	faNote,
	faPaperclip,
	faPen,
	faUsersRectangle,
} from "@shfortawesome/pro-duotone-svg-icons";
import { faPlus } from "@shfortawesome/pro-regular-svg-icons";

export const apps = (userSecurityFlags, pagepath) => {
	const isPatientArea = pagepath[0] === "patient-area";
	const pagesConfig = [
		{
			id: "journal",
			primaryText: "Journal",
			secondaryText: "Open",
			icon: faBook,
			trackLastUsed: true,
			type: "link",
			to: `${pagepath.slice(0, 2).join("/")}/journal`,
			condition: isPatientArea,
		},
		{
			id: "edit-patient",
			primaryText: "Edit patient",
			secondaryText: "Edit",
			icon: faPen,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: `edit-patient/${pagepath[1]}`,
			condition: isPatientArea,
		},
		{
			id: "add-journal-note",
			primaryText: "Add new note",
			secondaryText: "Add",
			icon: faNote,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: `${pagepath.slice(0, 2).join("/")}/journal/new-entry?type=note`,
			condition: isPatientArea,
		},
		{
			id: "add-journal-attachment",
			primaryText: "Add new attachment",
			secondaryText: "Add",
			icon: faPaperclip,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: `${pagepath.slice(0, 2).join("/")}/journal/new-entry?type=attachment`,
			condition: isPatientArea,
		},
		{
			id: "my-patients",
			primaryText: "My patients",
			secondaryText: "Open",
			icon: faUsersRectangle,
			trackLastUsed: true,
			type: "link",
			to: "mypatients",
			condition: userSecurityFlags?.includes("mypatients"),
		},
		{
			id: "add-patient",
			primaryText: "Add new patient",
			secondaryText: "Add",
			icon: faPlus,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: "add-patient",
			condition: userSecurityFlags?.includes("patient-addpatient"),
		},
		{
			id: "orders",
			primaryText: "Orders",
			secondaryText: "Open",
			icon: faClipboardList,
			trackLastUsed: true,
			type: "link",
			to: "orders",
			condition: userSecurityFlags?.includes("orders"),
		},
		{
			id: "scheduler",
			primaryText: "Scheduler",
			secondaryText: "Open",
			icon: faCalendarDays,
			trackLastUsed: true,
			type: "link",
			to: "scheduler",
			condition: userSecurityFlags?.includes("scheduler"),
		},
		{
			id: "add-appointment-new",
			primaryText: "Add new appointment",
			secondaryText: "Add",
			icon: faPlus,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: "add-appointment/new",
			condition: userSecurityFlags?.includes("appointment-addappointment"),
		},
		{
			id: "add-appointment-follow-up",
			primaryText: "Add follow-up appointment",
			secondaryText: "Add",
			icon: faPlus,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: "add-appointment/follow-up",
			condition: userSecurityFlags?.includes("appointment-addappointment"),
		},
	];

	const clinicianPages = pagesConfig.filter((page) => page.condition);
	return clinicianPages;
};

export const globalSearchApps = (datasource, pagepath) => [
	{
		displayName: "My patients",
		icon: faUsersRectangle,
		id: "mypatients",
		path: `../${datasource}/mypatients`,
	},
	{ displayName: "Orders", icon: faClipboardList, id: "orders", path: `../${datasource}/orders` },
	{
		displayName: "Scheduler",
		icon: faCalendarDays,
		id: "scheduler",
		path: `../${datasource}/scheduler`,
	},
	{
		displayName: "Journal",
		icon: faBook,
		id: "journal",
		path: `${pagepath.slice(0, 2).join("/")}/journal`,
		isPatientsApp: true,
	},
];
