import { useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { widgetRefetchInterval } from "../../globals/constants";
import { useMsal } from "@azure/msal-react";

export const useOrders = (filters, search, take, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const orders = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["orders", currentClinician, filters, search],
		queryFn: async ({ pageParam }) => {
			const data = await getOrders(
				instance,
				currentDataSource?.DataSourceId,
				filters,
				search,
				currentClinician,
				pageParam,
				take,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchInterval: widgetRefetchInterval,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !isNaN(take) && enabled,
	});

	const flatData = useMemo(() => orders.data?.pages?.flatMap((page) => page?.data), [orders?.data]);

	return { ...orders, data: flatData };
};

const getOrders = async (instance, dataSourceId, filters, search, clinicianId, skip = 0, take = 12) => {
	const searchParams = new URLSearchParams();
	for (const [filterName, filter] of Object.entries(filters)) {
		filter.getEndpointValue().forEach((value) => {
			searchParams.append(filterName, value);
		});
	}
	const data = await makeApiRequest(
		instance,
		`${dataSourceId}/clinician/${clinicianId}/orders?skip=${skip}&take=${take + 1}${
			searchParams.size > 0 ? `&${searchParams.toString()}` : ""
		}${search ? `&searchText=${encodeURIComponent(search)}` : ""}`,
		{},
		"v2",
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
