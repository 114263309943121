import { faNote } from "@shfortawesome/pro-duotone-svg-icons";
import { LinkWidget, WidgetAction } from "@streets-heaver/core";

export const PatientNotes = () => {
	return (
		<LinkWidget to={"journal?tab=notes"}>
			<WidgetAction
				text="Patient notes"
				icon={faNote}
				iconStyle={{
					"--fa-primary-color": "var(--backgroundMarigold)",
					"--fa-secondary-color": "var(--backgroundMarigold)",
				}}
			/>
		</LinkWidget>
	);
};
