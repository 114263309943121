import { Button } from "@streets-heaver/shui2";
import classes from "./DateToolbar.module.scss";
import { faChevronLeft as falChevronLeft, faChevronRight as falChevronRight } from "@shfortawesome/pro-light-svg-icons";
import {
	faChevronLeft as farChevronLeft,
	faChevronRight as farChevronRight,
} from "@shfortawesome/pro-regular-svg-icons";
import { faChevronLeft as fasChevronLeft, faChevronRight as fasChevronRight } from "@shfortawesome/pro-solid-svg-icons";
import { DateButton } from "../DateButton/DateButton";
import { TodayButton } from "../TodayButton/TodayButton";

export const DateToolbar = ({ showCalendar = true, currentDate, setCurrentDate, step = 86400000 }) => {
	return (
		<div className={classes.DateToolbar}>
			<div className={classes.TodayButton}>
				<TodayButton setCurrentDate={setCurrentDate} />
			</div>
			<div className={classes.DateNavigator}>
				<Button
					type="subtle"
					onClick={() => {
						const newDate = new Date();
						newDate.setTime(currentDate.getTime() - step);
						setCurrentDate(newDate);
					}}
					icon={{ restIcon: falChevronLeft, hoverIcon: farChevronLeft, pressedIcon: fasChevronLeft }}
					tooltipText="Previous date"
				/>
				<div className={classes.CentredText}>
					<DateButton
						calendarProps={{ date: currentDate, onSelected: (e) => setCurrentDate(e) }}
						enabled={showCalendar}
					/>
				</div>
				<Button
					type="subtle"
					onClick={() => {
						const newDate = new Date();
						newDate.setTime(currentDate.getTime() + step);
						setCurrentDate(newDate);
					}}
					icon={{ restIcon: falChevronRight, hoverIcon: farChevronRight, pressedIcon: fasChevronRight }}
					tooltipText="Next date"
				/>
			</div>
		</div>
	);
};
