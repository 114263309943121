import axios from "axios";

const defaultInstance = axios.create();

export const makeRequest = (
	url,
	{ method = "get", params, responseType, headers, body, axiosInstance = defaultInstance, signal } = {},
) => {
	return makeAxiosRequest(url, { method, params, responseType, headers, body, axiosInstance, signal }).then(
		(response) => response?.data,
	);
};

const makeAxiosRequest = (url, { method, params, responseType, headers, body, axiosInstance, signal }) => {
	const config = {
		params,
		responseType,
		headers: {
			"Content-Type": "application/json",
			...headers,
		},
		signal,
	};

	switch (method) {
		case "get":
			return axiosInstance.get(url, config);
		case "post":
			return axiosInstance.post(url, body, config);
		case "patch":
			return axiosInstance.patch(url, body, config);
		case "put":
			return axiosInstance.put(url, body, config);
		case "delete":
			return axiosInstance.delete(url, { ...config, data: body });
		case "search":
			return axiosInstance.request({ ...config, url, method: "SEARCH", data: body });
		default:
			break;
	}

	throw new Error(`Unrecognized http method ${method}`);
};
