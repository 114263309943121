import clsx from "clsx";
import dayjs from "dayjs";
import classes from "./TimeDisplay.module.scss";
import { Ghost } from "@streets-heaver/shui2";
import { durationFromDates, timeFormat, getCountdownBadge, nightsBetweenDates } from "../../utils";

export const TimeDisplay = ({ type = "OP", start, end, column = false, ghost, status, automationId }) => {
	return ghost ? (
		<Ghost height="20px" width="200px" />
	) : (
		<div className={clsx(classes.AppointmentTime, column && classes.column)} data-testid={automationId}>
			{getCountdownBadge(dayjs(), start, end, status)}
			{!column && <div className={classes.Divider} />}
			{type === "IP" ? (
				<div className={clsx(classes.Time)}>{nightsBetweenDates(start, end)}</div>
			) : (
				<div className={clsx(classes.Time)}>
					<p>{`${timeFormat(start)} - ${timeFormat(end)}`}</p>
					{!column && <div className={classes.Divider} />}
					<p className={classes.AppointmentLength}>{durationFromDates(start, end)}</p>
				</div>
			)}
		</div>
	);
};
