import { makeApiRequestV2 } from "../makeApiRequest";

export const getBookingsLookup = async (instance, { dataSourceId, patientId, skip, take, episodeOfCareId }) => {
	return await makeApiRequestV2(
		instance,
		`/datasource/${dataSourceId}/journal/lookup/bookings/v1?patientId=${patientId}&skip=${skip}&take=${take + 1}${
			episodeOfCareId ? `&episodeOfCareId=${episodeOfCareId}` : ""
		}`,
	);
};
