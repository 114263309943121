import { useAutoUpdateLastUsedAction } from "@streets-heaver/core";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { AddPatientFormContent, DiscardChangesDialog, FormWrapper, NoAccessPage } from "../../components";
import { addPatientFormDefaultValues } from "./AddPatientValues";
import { transformPatientDataForAdd } from "./transformPatientData";
import { useCreatePatient, useCurrentClinicians } from "../../api/hooks";

export const AddPatient = () => {
	useAutoUpdateLastUsedAction("add-patient");

	const navigate = useNavigate();
	const { userSecurityFlags } = useCurrentClinicians();
	const { mutateAsync: createPatient, status: createStatus } = useCreatePatient();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: addPatientFormDefaultValues(),
	});

	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);

	const onSubmit = (data) => {
		createPatient(transformPatientDataForAdd(data));
	};

	const onClose = () => navigate(-1);

	if (!userSecurityFlags.includes("patient-addpatient")) {
		return <NoAccessPage />;
	}

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper header={"Add new patient"}>
					<FormWrapper.Body>
						<AddPatientFormContent />
					</FormWrapper.Body>
					<FormWrapper.ButtonBar
						buttons={{
							primaryButton: {
								onClick: methods.handleSubmit(onSubmit),
								children: "Add patient",
								automationId: "add-patient-submit",
								disabled: createStatus === "pending",
								loading: createStatus === "pending",
							},
							secondaryButton: {
								onClick: methods.formState.isDirty ? () => setIsConfirmDiscardDialogVisible(true) : () => onClose(),
								children: "Cancel",
								automationId: "add-patient-cancel",
							},
						}}
					/>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
		</>
	);
};
