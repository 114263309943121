import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { makeRequest } from "./makeRequest";

const apiInstance = axios.create({ baseURL: `${import.meta.env.VITE_TENANTS_API_URL}/` });

export const makeApiRequestV2 = async (
	instance,
	url,
	{ method = "get", params, responseType, headers, body, signal } = {},
) => {
	const token = await acquireMsalToken(instance, ["api://93c2c2b7-62a3-44a7-9a44-37ad6e6712e4/Datasources.Read"]);
	return makeApiRequestTokenless(`clinicianportal/${url}`, {
		method,
		params,
		responseType,
		headers: { ...headers, "Content-Type": "application/json", Authorization: `Bearer ${token}` },
		body,
		signal,
	});
};

export const makeApiRequest = async (
	instance,
	url,
	{ method = "get", responseType, params, headers, body } = {},
	version = "v1",
) => {
	const token = await acquireMsalToken(instance, ["api://93c2c2b7-62a3-44a7-9a44-37ad6e6712e4/Datasources.Read"]);
	return makeApiRequestTokenless(`clinicianportal/${version}/datasource/${url}`, {
		method,
		params,
		responseType,
		headers: { ...headers, "Content-Type": "application/json", Authorization: `Bearer ${token}` },
		body,
	});
};

export const makeApiRequestTokenless = (url, { method = "get", params, responseType, headers, body, signal } = {}) => {
	return makeRequest(url, { method, params, responseType, headers, body, axiosInstance: apiInstance, signal });
};

export const acquireMsalToken = async (msalInstance, customScopes) => {
	await msalInstance.initialize();

	const accounts = msalInstance.getAllAccounts();
	const response = await msalInstance
		.acquireTokenSilent({
			scopes: customScopes,
			account: accounts[0],
		})
		.catch(async function (error) {
			if (error instanceof InteractionRequiredAuthError && error.errorCode !== "invalid_grant") {
				const response = await msalInstance.acquireTokenRedirect({
					scopes: customScopes,
					account: accounts[0],
				});
				return response;
			} else {
				throw error;
			}
		});
	return response?.accessToken;
};
