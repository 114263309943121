export class Filter {
	value;
	toStringFunction;
	getEndpointValueFunction;

	constructor(initialValue, { toStringFunction, getEndpointValueFunction }) {
		this.value = initialValue;
		this.toStringFunction = toStringFunction;
		this.getEndpointValueFunction = getEndpointValueFunction;
	}

	toString() {
		return this.toStringFunction(this.value);
	}

	getEndpointValue() {
		return this.getEndpointValueFunction ? this.getEndpointValueFunction(this.value) : this.value;
	}

	cloneWithNewValue(value) {
		return new Filter(value, {
			toStringFunction: this.toStringFunction,
			getEndpointValueFunction: this.getEndpointValueFunction,
		});
	}

	isDefault() {
		return this.value?.length < 1 || this.value[0] === false;
	}
}
