import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { isTesting } from "../../globals/constants";
import { useMsal } from "@azure/msal-react";

export const usePreviewPanelBooking = (type, bookingId) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const query = useQuery({
		queryKey: ["previewPanelBooking", currentClinician, type, bookingId],
		queryFn: async () => {
			const data = await makeApiRequest(
				instance,
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/panel/${type}/${bookingId}${
					isTesting ? "?isTesting=true" : ""
				}`,
				{},
				"v2",
			);
			if (data?.request?.response) throw new Error("Getting booking details for preview panel Failed");
			return data;
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!type && !!bookingId,
	});

	return query;
};
