import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useCancelAppointment } from "../../api/hooks";
import {
	AssociatedBookingsDialog,
	CancelAppointmentFormContent,
	DiscardChangesDialog,
	FormWrapper,
} from "../../components";
import { addCancelAppointmentValues, transformAppointmentDataForCancel } from "./transformAppointmentData";

export const CancelAppointmentForm = ({ data }) => {
	const { mutateAsync: cancelAppointment, status: cancelStatus } = useCancelAppointment();
	const navigate = useNavigate();
	const automationId = "cancel-appointment";

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: addCancelAppointmentValues(),
	});

	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);
	const [isCancelAssociatedDialogVisible, setIsCancelAssociatedDialogVisible] = useState(false);

	const onLinkedSubmit = (formData) => {
		setIsCancelAssociatedDialogVisible(formData);
	};

	const onSubmit = (formData) => {
		cancelAppointment(transformAppointmentDataForCancel(formData, data));
	};

	const onClose = () => navigate(-1);
	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper header={"Cancel an appointment"}>
					<FormWrapper.Body>
						<CancelAppointmentFormContent
							patient={data?.patient}
							episodeOfCare={data?.episodeOfCare}
							booking={data?.booking}
							linkedBookings={data?.linkedBookings}
							automationId={automationId}
						/>
					</FormWrapper.Body>
					<FormWrapper.ButtonBar
						buttons={{
							primaryButton: {
								onClick:
									data?.linkedBookings?.length > 0
										? methods.handleSubmit(onLinkedSubmit)
										: methods.handleSubmit(onSubmit),
								children: "Cancel appointment",
								automationId: `${automationId}-submit`,
								disabled: cancelStatus === "pending",
								loading: cancelStatus === "pending",
							},
							secondaryButton: {
								onClick: () => {
									navigate(-1);
								},
								children: "Discard",
								automationId: `${automationId}-cancel`,
							},
						}}
					/>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
			<AssociatedBookingsDialog
				visible={isCancelAssociatedDialogVisible}
				setVisible={setIsCancelAssociatedDialogVisible}
				onSubmit={(bool) => onSubmit({ ...isCancelAssociatedDialogVisible, isCancelAssociatedBookings: bool })}
				automationId={automationId}
			/>
		</>
	);
};
