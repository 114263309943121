import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";

export const useCreatePatient = () => {
	const { currentDataSource } = useDataSources();
	const { instance } = useMsal();
	const navigate = useNavigate();

	return useMutation({
		mutationKey: ["createPatient"],
		mutationFn: (data) => {
			return makeApiRequest(instance, `${currentDataSource?.DataSourceId}/create`, {
				method: "post",
				body: data,
			});
		},
		onError: (e) => {
			toast.error({
				title: "Error creating patient",
				subtitle: e?.response?.data?.detail ?? `Failed creating patient. Please try again later.`,
			});
		},
		onSuccess: () => {
			navigate(-1);
		},
	});
};
