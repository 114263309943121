import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useEditPatient } from "../../api/hooks";
import { DiscardChangesDialog, FormWrapper, EditPatientFormContent } from "../../components";
import { transformPatientDataForEdit } from "../AddPatient/transformPatientData";

export const EditPatientForm = ({ data, patientId }) => {
	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);
	const navigate = useNavigate();
	const { mutateAsync: editPatient, status: editStatus } = useEditPatient(patientId);

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: { ...data },
	});

	const onSubmit = (editedData) => {
		editPatient(transformPatientDataForEdit(editedData, data, patientId));
	};

	const onClose = () => navigate(-2);

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper
					header={"Edit Patient"}
					unscrollable
					buttons={{
						primaryButton: {
							onClick: methods.handleSubmit(onSubmit),
							children: "Save",
							automationId: "edit-patient-submit",
							disabled: editStatus === "pending",
							loading: editStatus === "pending",
						},
						secondaryButton: {
							onClick: methods.formState.isDirty ? () => setIsConfirmDiscardDialogVisible(true) : () => onClose(),
							children: "Cancel",
							automationId: "edit-patient-cancel",
						},
					}}
				>
					<FormWrapper.Body>
						<EditPatientFormContent />
					</FormWrapper.Body>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
		</>
	);
};
