import { PatientCardPreviewPanel } from "../PatientCard/PatientCard";
import { Sheet } from "@streets-heaver/core";

export const BookingSheet = ({ selectedBooking, setSelectedBooking }) => {
	return (
		<Sheet
			visible={selectedBooking}
			setVisible={() => setSelectedBooking(null)}
			title={`${selectedBooking?.type} booking`}
		>
			<Sheet.Content>
				<PatientCardPreviewPanel
					bookingType={selectedBooking?.type}
					bookingUniqueId={selectedBooking?.id}
					overrideTab={selectedBooking?.overrideTab}
				/>
			</Sheet.Content>
		</Sheet>
	);
};
