import { MenuButton } from "@streets-heaver/shui2";
import { timeOptions, intervalOptions } from "../../utils";
import { faGear, faCalendarDays, faSlidersSimple, faClock } from "@shfortawesome/pro-light-svg-icons";
import {
	faCalendarDays as fasCalendarDays,
	faSlidersSimple as fasSlidersSimple,
	faClock as fasClock,
} from "@shfortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScreenSize, useContentSizeClass } from "@streetsheaver/compucore";

export const SchedulerSettings = ({
	timescale,
	setTimescale,
	startTime,
	setStartTime,
	dayRange,
	setDayRange,
	hasPermission = true,
	compact = false,
}) => {
	const width = useContentSizeClass();

	let timescaleItems = [{ items: intervalOptions(timescale, setTimescale) }];

	let startTimeItems = [{ items: timeOptions(startTime, setStartTime) }];

	function updateDayRange(range) {
		setDayRange(range);
		localStorage.setItem("compucare.calendarDayRange", range);
	}

	let dayRangeItems = [
		{
			items: [
				{
					text: "Day view",
					isChecked: dayRange === 1,
					onClick: () => updateDayRange(1),
				},
				{
					text: "Work week",
					isChecked: dayRange === 5,
					onClick: () => updateDayRange(5),
				},
				{
					text: "Week view",
					isChecked: dayRange === 7,
					onClick: () => updateDayRange(7),
				},
			],
		},
	];

	if (!hasPermission) {
		return null;
	}

	return (
		<MenuButton
			automationId={"settings"}
			type="subtle"
			size="medium"
			icon={{ restIcon: faGear }}
			ariaLabel="Open scheduler settings menu"
			menuStrategy="absolute"
			menuProps={{
				hasIcons: true,
				items: [
					{
						header: "Settings",
						items: [
							{
								text: "Timescale",
								hasChevron: true,
								icon: <FontAwesomeIcon icon={faSlidersSimple} />,
								hoverIcon: <FontAwesomeIcon icon={fasSlidersSimple} />,
								subMenu: {
									hasCheckmarks: true,
									items: timescaleItems,
									automationId: "timescale-menu",
								},
								keepMenuOpen: true,
							},
							{
								text: "Diary start time",
								hasChevron: true,
								icon: <FontAwesomeIcon icon={faClock} />,
								hoverIcon: <FontAwesomeIcon icon={fasClock} />,
								subMenu: {
									hasCheckmarks: true,
									items: startTimeItems,
									style: { height: 250 },
									automationId: "diaryStartTime-menu",
								},
								keepMenuOpen: true,
							},
							{
								isDisabled: width < ScreenSize.TabletLandscape,
								text: "Day range",
								hasChevron: true,
								icon: <FontAwesomeIcon icon={faCalendarDays} />,
								hoverIcon: <FontAwesomeIcon icon={fasCalendarDays} />,
								subMenu: {
									hasCheckmarks: true,
									items: dayRangeItems,
									automationId: "dayrange-menu",
								},
								keepMenuOpen: true,
							},
						],
					},
				],
			}}
		>
			{!compact && "Settings"}
		</MenuButton>
	);
};
