import { useFormContext, useWatch, Controller } from "react-hook-form";
import { Radio, Switch } from "@streets-heaver/shui2/inputs";
import { notificationMethodOptions } from "../../../../../globals/defaultOptions";
import { FormField } from "../../../FormField/FormField";
import { getIfEmptyOrNull } from "../../../../../utils";
import { preferredNotificationErrorText } from "../../../../../globals/messages";

export const PreferredNotification = ({
	notificationMethodConfirmed,
	preferredNotificationMethod,
	mobilePhone,
	email,
	mainPhone,
	automationId,
}) => {
	const showPreferredNotificationMethodField = useWatch({ name: notificationMethodConfirmed.name });
	const form = useFormContext();

	return (
		<>
			<FormField label="Notification method confirmed?">
				<Controller
					control={form.control}
					name={notificationMethodConfirmed.name}
					render={({ field: { onChange, value } }) => (
						<Switch
							checked={value}
							onChange={onChange}
							label={value ? "Yes" : "No"}
							automationId={`${automationId}-notif-confirm`}
						/>
					)}
				/>
			</FormField>

			{showPreferredNotificationMethodField && (
				<FormField name={preferredNotificationMethod.name} label="Preferred notification method" isMandatory>
					<Controller
						name={preferredNotificationMethod.name}
						control={form.control}
						rules={{
							validate: {
								required: (val) =>
									!(showPreferredNotificationMethodField && getIfEmptyOrNull(val)) || preferredNotificationErrorText,
							},
						}}
						render={({ field: { value, onChange, ref: fieldRef }, fieldState: { error } }) => (
							<Radio
								options={notificationMethodOptions}
								value={value}
								onChange={(e) => {
									onChange(e).then(() =>
										form.trigger([preferredNotificationMethod.name, mobilePhone.name, email.name, mainPhone.name]),
									);
								}}
								isError={error}
								automationId={`${automationId}-notif-preferred`}
								ref={(ref) => {
									fieldRef({
										focus: () => ref.children[0].focus(),
									});
								}}
							/>
						)}
					/>
				</FormField>
			)}
		</>
	);
};
