import classes from "./NextOfKinForm.module.scss";
import { Badge, Button } from "@streets-heaver/shui2";
import { faChevronDown, faChevronUp, faPen, faTrashCan } from "@shfortawesome/pro-light-svg-icons";
import { formatNameAsCasual, formatNameAsReversed } from "../../../utils";
import clsx from "clsx";
import { useState } from "react";
import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@streets-heaver/core";

export const NextOfKinPersonItem = ({
	order,
	countOfItems,
	nok,
	isPrimary,
	onEditPressed,
	onDeletePressed,
	onOrderChange,
	hasError,
	automationId,
}) => {
	const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
	const formalName = formatNameAsReversed(nok?.name?.forename, nok?.name?.surname, nok?.name?.title);
	const casualName = formatNameAsCasual(nok?.name?.forename, nok?.name?.surname, nok?.name?.title);
	return (
		<div className={clsx(classes.nextOfKinItem, hasError && classes.error)}>
			<div className={clsx(classes.section, classes.main)}>
				<p className={classes.order}>{order + 1}.</p>
				<div className={classes.details}>
					<p className={classes.name}>{formalName}</p>
					<p className={classes.relationship}>{nok?.relationship?.label}</p>
				</div>
			</div>
			{isPrimary && <Badge color={"informative"} text={"Primary"} rounded />}
			<div className={classes.section}>
				<Button
					icon={{ restIcon: faPen }}
					type={"subtle"}
					onClick={(e) => {
						e.preventDefault();
						onEditPressed(order);
					}}
					ariaLabel={`Edit ${formalName}`}
					automationId={`${automationId}-edit`}
				/>
				<Button
					icon={{ restIcon: faTrashCan }}
					type={"subtle"}
					onClick={(e) => {
						e.preventDefault();
						setDeleteDialogVisible(true);
					}}
					ariaLabel={`Remove ${formalName}`}
					automationId={`${automationId}-remove`}
				/>
				<Button
					icon={{ restIcon: faChevronUp }}
					disabled={order === 0}
					type={"subtle"}
					onClick={(e) => {
						e.preventDefault();
						onOrderChange(order, -1);
					}}
					ariaLabel={`Increase next of kin priority for ${formalName}`}
					automationId={`${automationId}-increase`}
				/>
				<Button
					icon={{ restIcon: faChevronDown }}
					type={"subtle"}
					disabled={order === countOfItems - 1}
					onClick={(e) => {
						e.preventDefault();
						onOrderChange(order, +1);
					}}
					ariaLabel={`Decrease next of kin priority for ${formalName}`}
					automationId={`${automationId}-decrease`}
				/>
			</div>
			<Dialog visible={deleteDialogVisible} setVisible={setDeleteDialogVisible}>
				<DialogHeader hasCloseButton>Delete next of kin</DialogHeader>
				<DialogBody>You are about to delete the next of kin, {casualName}. Are you sure?</DialogBody>
				<DialogFooter
					primaryButton={{
						children: "Yes, delete",
						onClick: () => {
							onDeletePressed(order);
							setDeleteDialogVisible(false);
						},
					}}
					secondaryButton={{
						children: "No, return to form",
						onClick: () => setDeleteDialogVisible(false),
					}}
				/>
			</Dialog>
		</div>
	);
};
