import { LinkWidget, WidgetAction } from "@streets-heaver/core";
import { faCalendarPlus } from "@shfortawesome/pro-duotone-svg-icons";

export function AddAppointment() {
	return (
		<LinkWidget to={"add-appointment"}>
			<WidgetAction icon={faCalendarPlus} text="Add appointment" data-testId="add-appointment-widget" />
		</LinkWidget>
	);
}
