import classes from "./AbnormalResults.module.scss";
import { ActionPaths } from "../../../layouts/Layout/actionPaths";
import { Link } from "../../Link/Link";
import { ExpandedRequestResultRow } from "../../RequestCard/RequestCard";
import { NoAccessMessage } from "../../NoAccessMessage/NoAccessMessage";
import { useAbnormalResults } from "../../../api/hooks";
import { useEffect, useRef } from "react";
import { Widget, WidgetLocked, WidgetTitle } from "@streets-heaver/core";
import { faRectangleList } from "@shfortawesome/pro-duotone-svg-icons";

export const AbnormalResults = ({ patientId, hasPermission }) => {
	const { data, isLoading, fetchNextPage: fetchMoreResults } = useAbnormalResults(patientId, 5);
	const endOfListRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver((elements) => {
			elements.forEach((element) => {
				if (element.isIntersecting && patientId) {
					fetchMoreResults();
				}
			});
		});

		if (endOfListRef?.current !== null) {
			observer.observe(endOfListRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [endOfListRef, fetchMoreResults, patientId]);

	if (!hasPermission) {
		return (
			<Widget className={classes.widget}>
				<NoAccessMessage />
			</Widget>
		);
	}

	return (
		<Widget className={classes.widget}>
			<div className={classes.AbnormalResults}>
				<div className={classes.Header}>
					<WidgetTitle>Latest abnormal results</WidgetTitle>
					<Link text="View orders" icon to={`../orders/${ActionPaths.ReviewResults(patientId)}`} />
				</div>
				<div className={classes.Table}>
					<div className={classes.TableBody}>
						{getAbnormalResultsContent({ data, isLoading, patientId })}
						<div ref={endOfListRef} />
					</div>
				</div>
			</div>
		</Widget>
	);
};

const getAbnormalResultsContent = ({ data, isLoading, patientId }) => {
	if (isLoading)
		return (
			<WidgetLocked
				subtitle={"Loading abnormal results for this patient..."}
				icon={faRectangleList}
				colour="var(--foregroundBrandOne)"
			/>
		);

	if (data?.length > 0)
		return data?.map((result, i) => (
			<ExpandedRequestResultRow
				key={i}
				result={result}
				isResultsCard
				to={`../orders/${ActionPaths.ReviewResults(patientId)}/${ActionPaths.FormHistoryResults(
					result.formComponentId,
				)}`}
			/>
		));

	return (
		<WidgetLocked
			subtitle={"No abnormal results for this patient."}
			icon={faRectangleList}
			colour="var(--foregroundBrandOne)"
		/>
	);
};
