import { Button, Card } from "@streets-heaver/shui2";
import classes from "./FormWrapper.module.scss";
import { ScreenSize, useScreenSizeClass } from "@streetsheaver/compucore";
import { Outlet } from "react-router";
import clsx from "clsx";

const FormWrapper = ({ header, unscrollable, children, ...rest }) => {
	return (
		<div className={clsx(classes.page, unscrollable && classes.unscrollable)}>
			<Outlet />
			<Card className={classes.pageCard} border={false}>
				<h2 className={classes.header}>{header}</h2>
				{children}
				<ButtonBar buttons={rest?.buttons} />
			</Card>
		</div>
	);
};

const ButtonBar = ({ buttons }) => {
	const width = useScreenSizeClass();
	return (
		<div className={classes.buttonBar}>
			{buttons?.tertiaryButton && (
				<Button takeContainerWidth={width < ScreenSize.TabletPortrait} {...buttons?.tertiaryButton} />
			)}
			<div className={classes.rightButtons}>
				{buttons?.primaryButton && (
					<Button type="primary" takeContainerWidth={width < ScreenSize.TabletPortrait} {...buttons?.primaryButton} />
				)}
				{buttons?.secondaryButton && (
					<Button takeContainerWidth={width < ScreenSize.TabletPortrait} {...buttons?.secondaryButton} />
				)}
			</div>
		</div>
	);
};

FormWrapper.ButtonBar = ButtonBar;

const Body = ({ children }) => {
	return <div className={classes.body}>{children}</div>;
};

FormWrapper.Body = Body;

export { FormWrapper };
