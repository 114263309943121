import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@shfortawesome/pro-light-svg-icons";
import { Badge } from "@streets-heaver/shui2";
import classes from "./FiltersList.module.scss";

export const FiltersList = ({ appliedFilters, shownCondition, updateAppliedFilters }) => {
	return (
		<div className={classes.filtersList}>
			{Object.keys(appliedFilters).map((filterName) => {
				const filter = appliedFilters[filterName];
				const filterString = filter.toString();
				if ((typeof shownCondition === "function" && !shownCondition(filterName, filter)) || !filter.value?.length)
					return;
				return (
					<Badge
						size="large"
						text={filterString}
						type="tint"
						color="informative"
						icon={
							<button
								aria-label={`Remove ${filterString ?? "this"} filter`}
								onClick={() => {
									updateAppliedFilters((prev) => ({ ...prev, [filterName]: prev[filterName].cloneWithNewValue([]) }));
								}}
							>
								<FontAwesomeIcon icon={faXmarkCircle} className={classes.filterXButton} />
							</button>
						}
						style={{ flexDirection: "row-reverse" }}
						key={filterName}
						automationId="filter-badge"
					/>
				);
			})}
		</div>
	);
};
