import { makeApiRequestV2 } from "../makeApiRequest";

export const getEpisodesOfCaresLookup = async (instance, { dataSourceId, patientId, skip, take, searchText, id }) => {
	const params = new URLSearchParams();
	params.set("patientId", patientId);
	params.set("skip", skip);
	params.set("take", take + 1);
	if (searchText) params.set("searchText", searchText);
	if (id) params.set("episodeOfCareId", id);
	const data = await makeApiRequestV2(
		instance,
		`/datasource/${dataSourceId}/journal/lookup/episodeofcares/v1?${params.toString()}`,
	);
	return data;
};
