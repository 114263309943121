import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { argbToRgba } from "../../utils";
import { FEATURE_FLAGS } from "../../globals/featureFlags";
import { useVersionGate } from "@streets-heaver/core";
import { PatientBanner } from "@streets-heaver/shui2";
import { useCurrentClinicians, usePatientImage } from "../../api/hooks";

export const ClinicianPatientBanner = ({ patientData, isLoading }) => {
	const { userSecurityFlags } = useCurrentClinicians();
	const showPatientIdentity = useVersionGate(FEATURE_FLAGS.PATIENT_BANNER_IDENTITY);
	const enableJournal = useVersionGate(FEATURE_FLAGS.JOURNAL_APP);
	const { data: patientImage } = usePatientImage(patientData?.hasImage, patientData?.patientId, true);

	return (
		<PatientBanner
			isGhost={isLoading}
			patientData={{
				id: patientData?.patientPrimaryId,
				idLink: `../patient-area/${patientData?.patientId}`,
				surname: patientData?.surname || "",
				addressLineOne: patientData?.addressLine1,
				isPatPortalRegistered: patientData?.isPatientPortalRegistered,
				...patientData,
				profilePictureUrl: patientImage,
				genderColour: patientData?.genderColour && argbToRgba(patientData?.genderColour),
				medicalRecordStatus: null,
			}}
			columnData={[
				[
					{ title: "Email", result: patientData?.email },
					{ title: "Main phone", result: patientData?.mainPhone },
					{ title: "Work phone", result: patientData?.workPhone },
					{ title: "Mobile phone", result: patientData?.mobilePhone },
				],
				[
					{ title: "Patient ID", result: patientData?.patientId },
					{ title: "NHS number", result: patientData?.nhsNo },
					{ title: "Verification status", result: patientData?.verificationStatus },
					{ title: "Hospital ID", result: patientData?.hospitalId },
					{ title: "Case number", result: patientData?.caseNo },
				],
				[
					{ title: "Registered GP", result: patientData?.gp },
					{ title: "GP Practice", result: patientData?.gpPractice },
				],
				[
					{ title: "Sex (biological)", result: patientData?.sex },
					{ title: "Pronouns", result: patientData?.pronouns },
					...(showPatientIdentity
						? [
								{
									title: "Identity",
									result: patientData?.identity,
								},
							]
						: []),
					{ title: "Gender", result: patientData?.gender },
				],
			]}
			alerts={patientData?.alerts}
			actions={{
				editPatient: (!patientData?.isVIP ||
					(patientData?.isVIP && userSecurityFlags?.includes("editvippatients"))) && {
					to: `../${ActionPaths.EditPatientPreselected(patientData?.patientId)}`,
				},
				journal: enableJournal && { to: "journal" },
			}}
			automationId="patient-banner"
		/>
	);
};
