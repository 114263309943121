import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardListCheck } from "@shfortawesome/pro-duotone-svg-icons";
import classes from "./UnseenReports.module.scss";
import { useOrderStatusWidget } from "../../../api/hooks";
import { NoAccessMessage } from "../../NoAccessMessage/NoAccessMessage";
import { Widget } from "@streets-heaver/core";

export const UnseenReports = ({ patientId, hasPermission }) => {
	const unseenReportsQuery = useOrderStatusWidget(patientId);

	if (!hasPermission) {
		return (
			<Widget className={classes.widget}>
				<NoAccessMessage />
			</Widget>
		);
	}

	return (
		<Widget className={classes.widget}>
			<div className={classes.unseenReports} data-testid="unreadReports">
				<div className={classes.header}>
					<p className={classes.title}>Unseen reports</p>
					<FontAwesomeIcon icon={faClipboardListCheck} className={classes.icon} />
				</div>
				<p className={classes.number}>{unseenReportsQuery?.data?.sent ?? "-"}</p>
			</div>
		</Widget>
	);
};
