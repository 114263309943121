import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "./useClinicians";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useMsal } from "@azure/msal-react";

export const usePatientBanner = (patientId) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const query = useQuery({
		queryKey: ["patientBanner", currentClinician, patientId],
		queryFn: async () => {
			const data = await makeApiRequest(
				instance,
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/patient/${patientId}/banner`,
				{},
				"v2",
			);
			if (data?.request?.response) throw new Error("Getting patient details failed");
			return data;
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
	});

	return query;
};
