import {
	faUserDoctor as falUserDoctor,
	faCalendarDays as falCalendarDays,
	faUsers as falUsers,
	faClipboardList as falClipboardList,
	faUsersRectangle as falUsersRectangle,
} from "@shfortawesome/pro-light-svg-icons";
import {
	faUserDoctor as farUserDoctor,
	faCalendarDays as farCalendarDays,
	faUsers as farUsers,
	faClipboardList as farClipboardList,
	faUsersRectangle as farUsersRectangle,
} from "@shfortawesome/pro-regular-svg-icons";
import {
	faUserDoctor as fasUserDoctor,
	faCalendarDays as fasCalendarDays,
	faUsers as fasUsers,
	faClipboardList as fasClipboardList,
	faUsersRectangle as fasUsersRectangle,
} from "@shfortawesome/pro-solid-svg-icons";
import {
	faUserDoctor as fadUserDoctor,
	faCalendarDays as fadCalendarDays,
	faUsers as fadUsers,
	faClipboardList as fadClipboardList,
	faUsersRectangle as fadUsersRectangle,
} from "@shfortawesome/pro-duotone-svg-icons";
import { ScreenSize } from "@streetsheaver/compucore";

export const pages = (pagename, userSecurityFlags, setMobilePanelVisible, setIsSearchPanelVisible, width) => {
	const clinicianPages = [
		{
			title: "Home",
			icon: {
				restIcon: falUserDoctor,
				hoverIcon: farUserDoctor,
				pressedIcon: fasUserDoctor,
				selectedIcon: fadUserDoctor,
			},
			to: `./`,
			onClick: () => setMobilePanelVisible(false),
			selected: pagename === "" || pagename === undefined,
		},
	];

	if (userSecurityFlags?.includes(`navbar-patients`) && width > ScreenSize.TabletPortrait)
		clinicianPages.push({
			title: "Patients",
			icon: {
				restIcon: falUsers,
				hoverIcon: farUsers,
				pressedIcon: fasUsers,
				selectedIcon: fadUsers,
			},
			onClick: () => setIsSearchPanelVisible(true),
			selected: pagename === "patient-area",
		});

	clinicianPages.at(-1).divider = true;

	if (userSecurityFlags?.includes(`scheduler`))
		clinicianPages.push({
			title: "Scheduler",
			icon: {
				restIcon: falCalendarDays,
				hoverIcon: farCalendarDays,
				pressedIcon: fasCalendarDays,
				selectedIcon: fadCalendarDays,
			},
			to: "scheduler",
			onClick: () => setMobilePanelVisible(false),
			selected: pagename === "scheduler",
		});

	if (userSecurityFlags?.includes(`mypatients`))
		clinicianPages.push({
			title: "My patients",
			icon: {
				restIcon: falUsersRectangle,
				hoverIcon: farUsersRectangle,
				pressedIcon: fasUsersRectangle,
				selectedIcon: fadUsersRectangle,
			},
			to: "mypatients",
			onClick: () => setMobilePanelVisible(false),
			selected: pagename === "mypatients",
		});

	if (userSecurityFlags?.includes(`orders`))
		clinicianPages.push({
			title: "Orders",
			icon: {
				restIcon: falClipboardList,
				hoverIcon: farClipboardList,
				pressedIcon: fasClipboardList,
				selectedIcon: fadClipboardList,
			},
			to: "orders",
			onClick: () => setMobilePanelVisible(false),
			selected: pagename === "orders",
		});

	return clinicianPages;
};
