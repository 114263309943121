import { makeApiRequestV2 } from "../makeApiRequest";

export const patchUploadChunk = async (instance, { dataSourceId, patientId, fileId, chunk, offset, signal }) => {
	return await makeApiRequestV2(
		instance,
		`/datasource/${dataSourceId}/patient/${patientId}/attachment/uploadchunk/v1`,
		{
			method: "patch",
			body: { fileUploadId: fileId, data: chunk, startAt: offset },
			signal,
		},
	);
};
