import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@streets-heaver/core";

export const DiscardChangesDialog = ({ onClose, visible, setVisible }) => (
	<Dialog visible={visible} setVisible={setVisible}>
		<DialogHeader hasCloseButton>Discard form?</DialogHeader>
		<DialogBody>You are about to leave this page and lose all unsubmitted data. Are you sure?</DialogBody>
		<DialogFooter
			primaryButton={{
				children: "Yes, discard",
				onClick: () => {
					setVisible(false);
					onClose();
				},
			}}
			secondaryButton={{
				children: "Return to form",
				onClick: () => setVisible(false),
			}}
		/>
	</Dialog>
);
