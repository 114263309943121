import { DataSourceVersion, enabledAfter } from "@streets-heaver/core";

export const FEATURE_FLAGS = {
	PATIENT_BANNER_IDENTITY: enabledAfter(new DataSourceVersion(8, 83)),
	NEXT_APPOINTMENT_FOR_PATIENT: enabledAfter(new DataSourceVersion(8, 84)),
	REPORT_TEMPLATE_TYPE: enabledAfter(new DataSourceVersion(8, 84)),
	JOURNAL_APP: enabledAfter(new DataSourceVersion(8, 84)),
	PATIENT_IMAGES: enabledAfter(new DataSourceVersion(8, 85)),
	RESULTS_REFERENCE_RANGE: enabledAfter(new DataSourceVersion(8, 86)),
	RECENT_PATIENTS_LOOKUP: enabledAfter(new DataSourceVersion(8, 86)),
};
