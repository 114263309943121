import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";
import { useMsal } from "@azure/msal-react";

export const useCancellationReasonsLookup = (searchText) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "code-table", "cancellation-reasons", currentClinician, searchText],
		queryFn: async ({ pageParam }) => {
			const data = await getCancellationReasons(
				instance,
				currentDataSource?.DataSourceId,
				currentClinician,
				pageParam,
				12,
				searchText,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
	});
	return {
		...query,
		data: query?.data?.pages?.flatMap((page) => page?.data),
	};
};

const getCancellationReasons = async (instance, dataSourceId, clinicianId, skip = 0, take = 12, searchText = "") => {
	const data = await makeApiRequest(
		instance,
		`${dataSourceId}/clinician/${clinicianId}/lookup/cancellationreasons?&skip=${skip}&take=${take + 1}${
			searchText && searchText !== "" ? `&searchText=${encodeURIComponent(searchText)}` : ""
		}`,
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
