import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { useMsal } from "@azure/msal-react";

export const useEpisodeOfCaresLookup = (patientId) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const episodeOfCaresQuery = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "episodeOfCares", currentClinician, patientId],
		queryFn: async ({ pageParam }) => {
			const data = await getEpisodeOfCares(
				instance,
				currentDataSource?.DataSourceId,
				currentClinician,
				patientId,
				pageParam,
				12,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId,
	});
	return {
		...episodeOfCaresQuery,
		data: episodeOfCaresQuery?.data?.pages?.flatMap((page) => page?.data),
	};
};

const getEpisodeOfCares = async (instance, dataSourceId, clinicianId, patientId, skip = 0, take = 12) => {
	const data = await makeApiRequest(
		instance,
		`${dataSourceId}/clinician/${clinicianId}/lookup/episodeofcares?patientId=${patientId}&skip=${skip}&take=${
			take + 1
		}`,
		{},
		"v2",
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
