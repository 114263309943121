import { useMsal } from "@azure/msal-react";
import { JournalAPIProvider } from "@streets-heaver/journal";
import { useDataSources } from "@streetsheaver/compucore";
import { getJournalEntries } from "../api/journal/getJournalEntries";
import { getReadingPaneForAttachment } from "../api/journal/getReadingPaneForAttachment";
import { getReadingPaneForNote } from "../api/journal/getReadingPaneForNote";
import { getJournalBlob } from "../api/journal/getJournalBlob";
import { getEpisodesOfCaresLookup } from "../api/journal/getEpisodesOfCareLookup";
import { postPatientNote } from "../api/journal/postPatientNote";
import { getBookingsLookup } from "../api/journal/getBookingsLookup";
import { postBeginUpload } from "../api/journal/postBeginUpload";
import { patchUploadChunk } from "../api/journal/patchUploadChunk";
import { postFinishUpload } from "../api/journal/postFinishUpload";
import { deleteUpload } from "../api/journal/deleteUpload";
import { postPatientAttachment } from "../api/journal/postPatientAttachment";
import { getVersionHistory } from "../api/journal/getVersionHistory";
import { getAttachmentFolders } from "../api/journal/getAttachmentFolders";

export function ClinicianJournalAPIProvider({ children }) {
	const { currentDataSource } = useDataSources();
	const dataSourceId = currentDataSource?.DataSourceId;
	const { instance } = useMsal();

	if (!dataSourceId) throw new Error("No datasource id found.");

	const context = {
		getJournalEntries: (patientId, skip, take, filter, sort) =>
			getJournalEntries(instance, { dataSourceId, patientId, skip, take, filter, sort }),
		getReadingPaneForAttachment: (attachmentId) =>
			getReadingPaneForAttachment(instance, { dataSourceId, attachmentId }),
		getReadingPaneForNote: (noteId) => getReadingPaneForNote(instance, { dataSourceId, noteId }),
		getBlob: (blobStorageId) => getJournalBlob(instance, { dataSourceId, blobStorageId }),
		getEpisodeOfCareLookup: (patientId, { skip, take, searchText, id }) =>
			getEpisodesOfCaresLookup(instance, { dataSourceId, patientId, skip, take, searchText, id }),
		postPatientNoteEntry: (patientId, data) => postPatientNote(instance, { patientId, dataSourceId, ...data }),
		getBookingLookup: (patientId, { skip, take, episodeOfCareId }) =>
			getBookingsLookup(instance, { dataSourceId, patientId, skip, take, episodeOfCareId }),
		beginAttachmentUpload: (patientId, file) => postBeginUpload(instance, { dataSourceId, patientId, file }),
		uploadAttachmentChunk: (patientId, fileId, chunkDataBase64, chunkOffset, signal) =>
			patchUploadChunk(instance, {
				dataSourceId,
				patientId,
				fileId,
				chunk: chunkDataBase64,
				offset: chunkOffset,
				signal,
			}),
		finishAttachmentUpload: (patientId, fileId, fileSize, isCancel) =>
			postFinishUpload(instance, { dataSourceId, patientId, fileId, fileSize, isCancel }),
		removeAttachment: (patientId, fileId) => deleteUpload(instance, { dataSourceId, patientId, fileId }),
		postAttachmentEntry: (patientId, data) => postPatientAttachment(instance, { patientId, dataSourceId, ...data }),
		getVersionHistory: (patientAttachmentId, skip, take) =>
			getVersionHistory(instance, { dataSourceId, patientAttachmentId, skip, take }),
		getNewEntryFormSetup: () => getAttachmentFolders(instance, { dataSourceId }),
	};

	return <JournalAPIProvider {...context}>{children}</JournalAPIProvider>;
}
