import classes from "./OrderStatus.module.scss";
import { useMeasure } from "@uidotdev/usehooks";
import { useOrderStatusWidget } from "../../../api/hooks";
import { NoAccessMessage } from "../../NoAccessMessage/NoAccessMessage";
import { Widget, WidgetTitle, WidgetContent, Divider } from "@streets-heaver/core";

export const OrderStatus = ({ hasPermission, patientId }) => {
	const orderStatusQuery = useOrderStatusWidget(patientId);
	const [cardRef, { width: containerWidth }] = useMeasure();
	const limitStatuses = containerWidth < 282;
	const allStatuses = [
		{
			number: orderStatusQuery?.data?.awaitingReporting,
			title: "Awaiting report",
			colour: "var(--orderAwaitingReport)",
		},
		{ number: orderStatusQuery?.data?.reportEntered, title: "Report entered", colour: "var(--orderRequested)" },
		{ number: orderStatusQuery?.data?.reportVerified, title: "Verified", colour: "var(--orderReportVerified)" },
		{ number: orderStatusQuery?.data?.sent, title: "Unseen", colour: "var(--orderSent)" },
	];
	const statusesToLoop = limitStatuses ? [allStatuses.shift(), allStatuses.pop()] : allStatuses;

	if (!hasPermission) {
		return (
			<Widget className={classes.widget}>
				<NoAccessMessage />
			</Widget>
		);
	}
	return (
		<Widget className={classes.widget} ref={cardRef}>
			<WidgetTitle>Order status</WidgetTitle>
			<Divider />
			<WidgetContent className={classes.orderStatus}>
				{statusesToLoop?.length > 0 &&
					statusesToLoop?.map((status, i) => {
						return (
							<div
								className={classes.statusBox}
								key={`widget-orderstatus-status-${i}`}
								style={{ padding: limitStatuses ? "0px 8px" : "8px" }}
							>
								<div className={classes.statusLeft}>
									<div
										className={classes.statusLine}
										style={{ backgroundColor: status?.colour ?? "var(--foregroundOne)" }}
									/>
									<p className={classes.statusTitle}>{status?.title ?? "-"}</p>
								</div>
								<p className={classes.statusNumber}>{status?.number ?? "0"}</p>
							</div>
						);
					})}
			</WidgetContent>
		</Widget>
	);
};
