import { faPrint } from "@shfortawesome/pro-duotone-svg-icons";
import { LinkWidget, WidgetAction } from "@streets-heaver/core";

export function PrintLists() {
	return (
		<LinkWidget to={`print/${JSON.parse(sessionStorage.getItem("compucare.printform.bookingType")) || "clinic"}`}>
			<WidgetAction icon={faPrint} text="Print lists" data-testId="print-lists-widget" />
		</LinkWidget>
	);
}
