import classes from "./MobileOrderCell.module.scss";
import { PatientNameAndId } from "../PatientNameAndID/PatientNameAndID";
import { PatientSeenRequest, ServiceRequests } from "../BadgeCells/BadgeCells";
import { MinimiseButton } from "../../../MinimiseButton/MinimiseButton";
import { ServiceRequestRow } from "../ServiceRequestRow/ServiceRequestRow";
import { faCheck } from "@shfortawesome/pro-regular-svg-icons";
import { ActionPaths } from "../../../../layouts/Layout/actionPaths";
import { SwipeInteraction } from "../../../SwipeInteraction/SwipeInteraction";
import { useUpdateOrderStatus } from "../../../../api/hooks/useUpdateOrderStatus";
import { useCurrentClinicians } from "../../../../api/hooks/useClinicians";
import clsx from "clsx";

export const MobileOrderCell = ({ row }) => {
	const { userSecurityFlags } = useCurrentClinicians();
	const hasSomeSent = row?.original?.orders.some((request) => request?.status === "Sent");
	const hasSomeSeen = row?.original?.orders.some((request) => request?.status === "Seen");
	const { mutate: markAsSeen } = useUpdateOrderStatus(!hasSomeSent ? "sent" : "seen");

	return (
		!row?.original?.filler && (
			<SwipeInteraction
				enabled={(hasSomeSeen || hasSomeSent) && userSecurityFlags.includes("orders-markseen")}
				actionText={!hasSomeSent ? "Unseen" : "Seen"}
				actionIcon={faCheck}
				onSwipeAction={() =>
					markAsSeen(
						row?.original?.orders
							.filter((request) => request?.status === (!hasSomeSent ? "Seen" : "Sent"))
							.map((order) => order.orderId),
					)
				}
			>
				<div className={clsx(classes.orderCell, classes.addedPadding)} onClick={() => row.toggleExpanded()}>
					<div className={classes.topCell}>
						<div className={classes.info}>
							<div className={classes.header}>
								<PatientNameAndId details={row?.original} isOrders />
							</div>
							<div className={classes.row}>
								<PatientSeenRequest allSeen={row?.original?.allSeen} orders={row?.original?.orders} />
								<ServiceRequests requests={row?.original?.orders} size="medium" />
							</div>
						</div>
						<MinimiseButton minimise={!row?.getIsExpanded()} setMinimise={() => row.toggleExpanded()} />
					</div>
					{row?.getIsExpanded() && (
						<div className={classes.bottomCell}>
							{row?.original?.orders?.map((order, orderIndex) => (
								<ServiceRequestRow
									key={`${row?.original?.orderUniqueId}-${orderIndex}`}
									request={order}
									resultCallback={() => ActionPaths.ReviewResults(row?.original?.patientId)}
									type="mobileTable"
								/>
							))}
						</div>
					)}
				</div>
			</SwipeInteraction>
		)
	);
};
