import { CompoundButton } from "@streets-heaver/shui2";
import { Dialog, DialogBody, DialogHeader } from "@streets-heaver/core";
import { useLocation, useNavigate } from "react-router";
import { useDataSources } from "@streetsheaver/compucore";
import { faPlus } from "@shfortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./AppointmentTypeModal.module.scss";

export const AppointmentTypeModal = () => {
	const { currentDataSource } = useDataSources();
	const navigate = useNavigate();
	const { state } = useLocation();
	return (
		<Dialog visible setVisible={() => navigate("..")}>
			<DialogHeader hasCloseButton>Please choose</DialogHeader>
			<DialogBody>
				<div className={classes.appointmentButtons}>
					<CompoundButton
						illustration={<FontAwesomeIcon icon={faPlus} className={classes.icon} />}
						primaryText="New"
						secondaryText="Create a new appointment with a new episode of care"
						onClick={() => {
							navigate(`/${currentDataSource.DataSourceId}/add-appointment/new`, { replace: true, state: state });
						}}
					/>
					<CompoundButton
						illustration={<FontAwesomeIcon icon={faPlus} className={classes.icon} />}
						primaryText="Follow up"
						secondaryText="Create a follow up appointment with an existing episode of care"
						onClick={() => {
							navigate(`/${currentDataSource.DataSourceId}/add-appointment/follow-up`, { replace: true, state: state });
						}}
					/>
				</div>
			</DialogBody>
		</Dialog>
	);
};
