import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@streets-heaver/core";
import { useLocation, useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { NoAccessMessage } from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { usePrintList } from "../../api/hooks";
import { PrintForm } from "../../components/FormComponents";
import { transformPrintData } from "./transformPrintData";
import classes from "./PrintModal.module.scss";

export const PrintModal = ({ type }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { userSecurityFlags } = useCurrentClinicians();
	const { mutateAsync: printList, status: printStatus } = usePrintList(type);
	const automationId = "print-lists";

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: {
			startDate: new Date(),
			endDate: new Date(),
			site: JSON.parse(sessionStorage.getItem("printform.site")) || null,
			template: JSON.parse(sessionStorage.getItem(`printform.template.${type}`)) || {
				reportTemplateId: 0,
				name: "System Defined Template",
			},
		},
	});

	const onSubmit = (data) => {
		sessionStorage.setItem("printform.site", JSON.stringify(data.site));
		sessionStorage.setItem(`printform.template.${type}`, JSON.stringify(data.template));
		printList(transformPrintData(data)).then(() => navigate({ pathname: "..", search: location.search }));
	};

	const onClose = () => navigate({ pathname: "..", search: location.search });

	if (!userSecurityFlags?.includes(`toolbar-printing`))
		return (
			<Dialog visible setVisible={onClose}>
				<DialogHeader hasCloseButton>Print lists</DialogHeader>
				<DialogBody>
					<div className={classes.noAccessBody}>
						<NoAccessMessage />
					</div>
				</DialogBody>
				<DialogFooter
					primaryButton={{ children: "Print", disabled: true }}
					secondaryButton={{ children: "Cancel", onClick: onClose }}
				/>
			</Dialog>
		);

	return (
		<Dialog visible setVisible={onClose}>
			<DialogHeader hasCloseButton>Print lists</DialogHeader>
			<DialogBody>
				<FormProvider {...methods}>
					<PrintForm type={type} automationId={automationId} />
				</FormProvider>
			</DialogBody>
			<DialogFooter
				primaryButton={{
					children: "Print",
					onClick: methods.handleSubmit(onSubmit),
					disabled: printStatus === "pending",
					loading: printStatus === "pending",
					"data-testid": `${automationId}-submit`,
				}}
				secondaryButton={{ children: "Cancel", onClick: onClose, "data-testid": `${automationId}-cancel` }}
			/>
		</Dialog>
	);
};
