import { Divider } from "@streets-heaver/shui2";
import { Name } from "../Sections/Name/Name";
import { Identity } from "../Sections/Identity/Identity";
import { Identifiers } from "../Sections/Identifiers/Identifiers";
import { Contact } from "../Sections/Contact/Contact";
import { NextOfKin } from "../Sections/NextOfKin/NextOfKin";
import { Shared } from "../Sections/Shared/Shared";
import { useFormContext } from "react-hook-form";
import classes from "./PatientForm.module.scss";
import {
	forenameSchemaError,
	surnameSchemaLengthError,
	surnameSchemaRequiredError,
	titleSchemaError,
} from "../../../../globals/messages";

export const AddPatientFormContent = () => {
	const methods = useFormContext();
	const automationId = "add-patient";

	const title = methods.register("name.primary.title", { maxLength: { value: 25, message: titleSchemaError } });
	const surname = methods.register("name.primary.surname", {
		required: { value: true, message: surnameSchemaRequiredError },
		maxLength: { value: 200, message: surnameSchemaLengthError },
	});
	const forename = methods.register("name.primary.forename", {
		maxLength: { value: 200, message: forenameSchemaError },
	});

	return (
		<div className={classes.sections}>
			<Name title={title} surname={surname} forename={forename} automationId={automationId} />
			<Divider />
			<Identity automationId={automationId} />
			<Divider />
			<Contact automationId={automationId} />
			<Divider />
			<Identifiers automationId={automationId} />
			<Divider />
			<NextOfKin automationId={automationId} />
			<Divider />
			<Shared automationId={automationId} />
		</div>
	);
};
