import { createContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDataSources } from "@streetsheaver/compucore";
import { useVersion } from "@streets-heaver/core";
import { useClinicians } from "../api/hooks/useClinicians";
import { isTesting } from "../globals/constants";

export const ClinicianContext = createContext();

export const ClinicianProvider = ({ children }) => {
	const { currentDataSource } = useDataSources();
	const {
		data: setupData,
		status: setupStatus,
		error: cliniciansError,
		isLoading: cliniciansLoading,
	} = useClinicians();
	const [currentClinician, setCurrentClinician] = useState(null);
	const { setDataSourceVersion } = useVersion();

	useEffect(() => {
		setCurrentClinician(null);
	}, [currentDataSource]);

	useEffect(() => {
		if (setupData) setDataSourceVersion(setupData.dataSourceVersion);
	}, [setupData, setDataSourceVersion]);

	const formattedClinicians =
		cliniciansLoading || !setupData
			? []
			: setupData?.clinicians?.map((clinician) => {
					return {
						...clinician,
						text: clinician?.fullnameReverse,
						onClick: () => {
							setNewClinician(clinician.uniqueId);
						},
						isChecked: clinician?.uniqueId === currentClinician,
					};
				});

	const setNewClinician = (newClinicianId) => {
		if (newClinicianId === currentClinician) {
			toast({ title: "Clinician already selected.", icon: "information" });
			return;
		}

		const newClinicianFullname = formattedClinicians?.find(
			(clinician) => clinician.uniqueId === newClinicianId,
		)?.fullnameReverse;
		if (newClinicianId && validateClinician(newClinicianId)) {
			localStorage.setItem("compucare.currentClinician", newClinicianId);
			if (currentClinician && !isTesting) {
				toast.success({
					title: "Clinician changed",
					subtitle: `Current clinician changed to ${newClinicianFullname}`,
				});
			}
			setCurrentClinician(newClinicianId);
		} else {
			if (!isTesting)
				toast.error({
					title: "Default clinician selected",
					subtitle: `Selected clinician "${newClinicianFullname}" is unavailable. Falling back to default: "${formattedClinicians[0]?.fullnameReverse}".`,
				});
			localStorage.setItem("compucare.currentClinician", formattedClinicians[0].uniqueId);
			setCurrentClinician(formattedClinicians[0].uniqueId);
		}
	};

	const validateClinician = (clinician) => {
		const isValidClinician = setupData?.clinicians?.some((clin) => clin?.uniqueId === clinician);
		return isValidClinician;
	};

	useEffect(() => {
		if (!currentClinician && !cliniciansLoading && formattedClinicians?.[0]) {
			if (localStorage.getItem("compucare.currentClinician")) {
				setNewClinician(localStorage.getItem("compucare.currentClinician"));
			} else {
				setNewClinician(formattedClinicians[0].uniqueId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentClinician, formattedClinicians]);

	return (
		<ClinicianContext.Provider
			value={{
				currentClinician: currentClinician,
				clinicians: formattedClinicians,
				setNewClinician: setNewClinician,
				userSecurityFlags: setupData?.userSecurityFlags || [],
				loadingClinicians: cliniciansLoading || !setupData,
				errorClinicians: cliniciansError,
				setupStatus,
			}}
		>
			{children}
		</ClinicianContext.Provider>
	);
};
