import { faUserDoctor as fadUserDoctor } from "@shfortawesome/pro-duotone-svg-icons";
import { faUserDoctor } from "@shfortawesome/pro-light-svg-icons";
import { faUserDoctor as farUserDoctor } from "@shfortawesome/pro-regular-svg-icons";
import { faUserDoctor as fasUserDoctor } from "@shfortawesome/pro-solid-svg-icons";
import {
	ActionsMenuButton,
	GlobalSearchAllApps,
	GlobalSearchAllPatients,
	GlobalSearchContent,
	GlobalSearchPanel,
	SearchFilter,
	ShuiToaster,
} from "@streets-heaver/core";
import { CompucareFrame, ScreenSize, useScreenSizeClass } from "@streetsheaver/compucore";
import { useState } from "react";
import { useLocation } from "react-router";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { ClinicianSwitchModal } from "../../components";
import { getHasActionsBar } from "../../utils";
import { apps, globalSearchApps } from "./apps";
import { pages } from "./pages";
import { useBreadcrumbs } from "./useBreadcrumbs";
import { usePatientsLookup } from "../../api/hooks";
import {
	GlobalSearchPatient,
	GlobalSearchPatientGhost,
} from "../../components/globalSearchPatient/globalSearchPatient";

const Frame = ({ children }) => {
	const compucareURL =
		window.location.host === "clinician.streets-heaver.com"
			? "https://compucare.streets-heaver.com/home"
			: "https://compucare-dev.streets-heaver.com/home";

	const { currentClinician, clinicians, userSecurityFlags, setupStatus } = useCurrentClinicians();
	const [isMobileVisible, setIsMobileVisible] = useState(false);
	const [isClinicianSwitchModalVisible, setIsClinicianSwitchModalVisible] = useState(false);

	const location = useLocation();
	const pathname = location.pathname;
	const [_, datasource, ...pagepath] = pathname.split("/");

	const width = useScreenSizeClass();

	const breadcrumbs = useBreadcrumbs(pathname, () => setIsClinicianSwitchModalVisible(true));
	const [term, setTerm] = useState("");
	const [isSearchPanelVisible, setIsSearchPanelVisible] = useState(false);
	const [mobileSearchMenu, setMobileSearchMenu] = useState("Search");
	const [searchFilter, setSearchFilter] = useState(null);

	const patients = usePatientsLookup(term);
	const globalSearchAppsList = globalSearchApps(datasource, pagepath);

	return (
		<CompucareFrame
			hasActionsBar={getHasActionsBar(pagepath, width)}
			breadcrumbs={breadcrumbs}
			compucareTo={compucareURL}
			mobilePanel={{
				visible: isMobileVisible,
				setVisible:
					mobileSearchMenu === "All apps" || mobileSearchMenu === "All available patients"
						? () => setMobileSearchMenu("Search")
						: (e) => setIsMobileVisible(e),
				...(mobileSearchMenu === "All apps" && {
					children: (
						<GlobalSearchAllApps apps={globalSearchAppsList} searchTerm={term} patientLinkPrefix="patient-area/" />
					),
					title: mobileSearchMenu,
				}),
				...(mobileSearchMenu === "All available patients" && {
					children: (
						<GlobalSearchAllPatients
							patientsQuery={patients}
							searchTerm={term}
							patientItemRender={GlobalSearchPatient}
							patientItemGhostRender={GlobalSearchPatientGhost}
						/>
					),
					title: mobileSearchMenu,
				}),
			}}
			menuItems={pages(
				pagepath[0],
				userSecurityFlags,
				setIsMobileVisible,
				() => {
					setSearchFilter(SearchFilter.PATIENTS);
					setIsSearchPanelVisible(true);
				},
				width,
			)}
			{...(width <= ScreenSize.TabletPortrait && {
				accounts: currentClinician && clinicians?.length > 0 ? clinicians : undefined,
			})}
			accountsMenuInfo={
				setupStatus === "success" && {
					title: currentClinician
						? clinicians?.find((clinician) => clinician.uniqueId === currentClinician)?.fullnameReverse
						: "Clinician",
					truncate: true,
					icon: {
						restIcon: faUserDoctor,
						hoverIcon: farUserDoctor,
						pressedIcon: fasUserDoctor,
						selectedIcon: fadUserDoctor,
					},
					...(width > ScreenSize.TabletPortrait && {
						onClick: () => {
							setIsClinicianSwitchModalVisible(true);
						},
					}),
				}
			}
			enabledDefaultMenuItems={{ apps: false, patients: false, people: false }}
			customHomeIcon={<ActionsMenuButton actions={apps(userSecurityFlags, pagepath)} />}
			enabledIconButtons={{ notifications: true }}
			searchBoxOptions={{
				display: "button",
				onClick: () => setIsSearchPanelVisible(true),
				renderResultsMobile: (term) => {
					setTerm(term);
					return (
						<GlobalSearchContent
							apps={globalSearchAppsList}
							searchTerm={term}
							patientsQuery={patients}
							patientLinkPrefix={`/${datasource}/patient-area/`}
							setMenu={setMobileSearchMenu}
							filter={searchFilter}
							setFilter={setSearchFilter}
							patientItemRender={GlobalSearchPatient}
							patientItemGhostRender={GlobalSearchPatientGhost}
						/>
					);
				},
			}}
		>
			{children}
			{width > ScreenSize.Mobile && (
				<GlobalSearchPanel
					apps={globalSearchAppsList}
					isVisible={isSearchPanelVisible}
					setIsVisible={setIsSearchPanelVisible}
					onSearch={setTerm}
					patientsQuery={patients}
					patientLinkPrefix={`/${datasource}/patient-area/`}
					filter={searchFilter}
					setFilter={setSearchFilter}
					patientItemRender={GlobalSearchPatient}
					patientItemGhostRender={GlobalSearchPatientGhost}
				/>
			)}
			<ClinicianSwitchModal
				isVisible={isClinicianSwitchModalVisible}
				onClose={() => {
					setIsClinicianSwitchModalVisible(false);
					setIsMobileVisible(false);
				}}
			/>
			<ShuiToaster />
		</CompucareFrame>
	);
};

export default Frame;
