import { useMutationState } from "@tanstack/react-query";
import { Badge } from "@streets-heaver/shui2";
import classes from "./BadgeCells.module.scss";

export const ServiceRequests = ({ requests, size = "larger" }) => {
	if (requests?.length < 1) return;

	const firstBadge = <Badge text={requests?.[0]?.serviceRequestName} size={size} type={"tint"} color={"informative"} />;
	if (requests?.length < 2) return firstBadge;

	const moreBadge = <Badge text={`+${requests?.length - 1} more`} size={size} type={"tint"} color={"informative"} />;
	return (
		<span className={classes.serviceRequests}>
			{firstBadge}
			{moreBadge}
		</span>
	);
};

export const SeenRequest = ({ isRead, size = "medium" }) => {
	return isRead ? <Badge automationId="seen-badge" text={"Seen"} type={"tint"} size={size} color={"success"} /> : <></>;
};

export const PatientSeenRequest = ({ allSeen, orders }) => {
	const pendingOrders = useMutationState({
		filters: { mutationKey: ["markSeen"], status: "pending" },
		select: (mutation) => mutation.state.variables,
	}).flat();

	let allSeenOrPending = false;
	if (orders?.every((order) => order.status === "Seen" || order.status === "Sent")) {
		const sentOrders = orders?.filter((order) => order?.status === "Sent").map((order) => order?.orderId);

		allSeenOrPending = sentOrders.every((order) => pendingOrders.includes(order));
	}

	return <SeenRequest isRead={allSeen || allSeenOrPending} />;
};
