import { useQuery } from "@tanstack/react-query";
import { makeApiRequestV2 } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { widgetRefetchInterval } from "../../globals/constants";
import { useMsal } from "@azure/msal-react";

export const useRecentPatients = (enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const query = useQuery({
		queryKey: ["recentPatients", currentClinician],
		queryFn: async () => {
			const data = await makeApiRequestV2(
				instance,
				`datasource/${currentDataSource?.DataSourceId}/clinician/${currentClinician}/patients/recent/v1`,
			);
			if (data?.request?.response) throw new Error("Getting recent patients Failed");
			return data;
		},
		refetchInterval: widgetRefetchInterval,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && enabled,
	});

	return query;
};
