import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";

export const useEditPatient = (patientId) => {
	const { currentDataSource } = useDataSources();
	const navigate = useNavigate();
	const { instance } = useMsal();

	return useMutation({
		mutationKey: ["editPatient"],
		mutationFn: (data) => {
			return makeApiRequest(instance, `${currentDataSource?.DataSourceId}/edit`, {
				method: "put",
				body: data,
			});
		},
		onError: (e) => {
			toast.error({
				title: "Error editing patient",
				subtitle: e?.response?.data?.detail ?? `Failed editing patient ${patientId}. Please try again later.`,
			});
		},
		onSuccess: () => {
			navigate(-1);
		},
	});
};
