import { faUserPlus } from "@shfortawesome/pro-duotone-svg-icons";
import { LinkWidget, WidgetAction } from "@streets-heaver/core";

export function NewPatient() {
	return (
		<LinkWidget to={"add-patient"}>
			<WidgetAction icon={faUserPlus} text="New patient" data-testId="new-patient-widget" />
		</LinkWidget>
	);
}
