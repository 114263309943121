import { Dropdown } from "@streets-heaver/shui2";
import { Timebox } from "@streets-heaver/shui2/inputs";
import { useDebounce } from "@streetsheaver/compucore";
import { isValidSQLDate } from "@streets-heaver/core";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../../../FormField/FormField";
import { SiteLookup } from "../../../lookups";
import { useClinicianAvailability } from "../../../../../api/hooks";
import { FormDatebox } from "../../../FormDatebox/FormDatebox.jsx";
import { getDurationOptions } from "./durationOptions.js";
import { getAvailabilityFromTime, isAvailableTime, isTimeInvalid } from "./timeOptions.js";
import { timeRegex } from "../../../../../globals/regex.js";
import {
	sqlDateErrorMessage,
	timeHelperText,
	dateErrorMessage,
	timeErrorMessage,
	durationHelperText,
	bookingDurationSchemaError,
	bookingDateFutureSchemaError,
	bookingTimeSchemaError,
	bookingTimePastRefinementError,
	bookingTimeInvalidError,
	clinicianAvailabilityError,
} from "../../../../../globals/messages.js";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export const BookingAvailability = ({ automationId }) => {
	const {
		control,
		watch,
		setValue,
		resetField,
		trigger,
		formState: { errors, dirtyFields },
	} = useFormContext();
	const [selectedAvailability, setSelectedAvailability] = useState();

	const dateName = "date";
	const timeName = "time";
	const durationName = "duration";
	const siteName = "site";

	const selectedDate = watch(dateName);
	const selectedTime = watch(timeName);

	const debouncedDate = useDebounce(selectedDate, 600);
	const debouncedTime = useDebounce(selectedTime, 600);

	const { data, isLoading, error } = useClinicianAvailability(debouncedDate, isValidSQLDate(dayjs(debouncedDate)));
	const durationOptions = getDurationOptions(debouncedTime, debouncedDate, selectedAvailability, data?.outpatient24h);
	const hasValidData = !isLoading && !error && data;

	useEffect(() => {
		if (debouncedDate) trigger(dateName);
		if (debouncedTime) trigger(timeName);
	}, [debouncedDate, debouncedTime, data, isLoading, error, trigger]);

	useEffect(() => {
		if (debouncedTime && debouncedDate && hasValidData) {
			const availability = getAvailabilityFromTime(debouncedTime, debouncedDate, data);
			setSelectedAvailability(availability);
		}
	}, [setSelectedAvailability, debouncedTime, debouncedDate, data, hasValidData, resetField]);

	useEffect(() => {
		if (selectedAvailability) {
			setValue(siteName, selectedAvailability?.site);
			trigger(siteName);
		}
	}, [setValue, selectedAvailability, debouncedTime, trigger]);

	useEffect(() => {
		if (dirtyFields?.date) {
			resetField(timeName, { defaultValue: "" });
			resetField(durationName, { defaultValue: null });
		}
	}, [dirtyFields, debouncedDate, resetField]);

	useEffect(() => {
		if (dirtyFields?.time) resetField(durationName, { defaultValue: null });
	}, [dirtyFields, debouncedTime, resetField]);

	return (
		<>
			<FormField label="Date" name={dateName} isMandatory>
				<Controller
					name={dateName}
					control={control}
					rules={{
						validate: {
							sqlDate: (value) => {
								if (!isValidSQLDate(dayjs(value))) {
									return sqlDateErrorMessage;
								}
							},
							invalidDate: (value) => {
								if (value && hasValidData) {
									return data?.outpatient24h || data?.availabilities?.length > 0 || dateErrorMessage;
								} else {
									return true;
								}
							},
							futureDate: (value) => !dayjs(value).isBefore(dayjs(), "day") || bookingDateFutureSchemaError,
							apiError: (value) => (value && !error) || (value && clinicianAvailabilityError),
						},
					}}
					render={({ field: { value, onChange, error, ref } }) => (
						<FormDatebox
							value={value}
							onChange={onChange}
							portalTarget={document.body}
							automationId={`${automationId}-date`}
							isError={error}
							ref={ref}
						/>
					)}
				/>
			</FormField>
			<FormField label="Time" name={timeName} isMandatory helperText={timeHelperText}>
				<Controller
					name={timeName}
					control={control}
					rules={{
						validate: {
							required: (value) => (value && timeRegex.test(value)) || bookingTimeSchemaError,
							futureTime: (value) =>
								(value && !dayjs(`${dayjs(selectedDate).format("YYYY-MM-DD")} ${value}`).isBefore(dayjs())) ||
								(value && bookingTimePastRefinementError),
							availableTime: (value) => (value && isAvailableTime(value)) || (value && bookingTimeInvalidError),
							validTime: (value) =>
								!(value && hasValidData && isTimeInvalid(value, debouncedDate, data)) || (value && timeErrorMessage),
						},
					}}
					render={({ field: { value, onChange, ref, error } }) => (
						<div style={{ width: 240 }}>
							<Timebox
								type="filledDarker"
								size="large"
								dropdownStep={15}
								inputStep={15}
								value={value}
								onChange={(e) => {
									onChange(e);
								}}
								ref={ref}
								disabled={isLoading || errors?.date}
								isTimeDisabled={(time) => isTimeInvalid(time, debouncedDate, data)}
								hasError={error}
								portalTarget={document.body}
								automationId={`${automationId}-time`}
							/>
						</div>
					)}
				/>
			</FormField>
			<FormField label="Duration" name={durationName} isMandatory helperText={durationHelperText}>
				<Controller
					name={durationName}
					control={control}
					rules={{
						validate: {
							required: (value) => value || bookingDurationSchemaError,
						},
					}}
					render={({ field: { value, onChange, ref, error } }) => (
						<div style={{ width: 240 }}>
							<Dropdown
								placeholder={{ label: "Select a duration" }}
								value={value}
								onChange={onChange}
								type={"filledDarker"}
								size="large"
								options={!!durationOptions && durationOptions}
								loading={isLoading}
								disabled={!selectedTime || !durationOptions || errors?.time}
								reference={ref}
								isError={error}
								portalTarget={document.body}
								automationId={`${automationId}-duration`}
							/>
						</div>
					)}
				/>
			</FormField>
			<div style={{ width: 340 }}>
				<SiteLookup
					name={siteName}
					isViewOnly={!data?.outpatient24h || isLoading || !data}
					isMandatory
					automationId={automationId}
				/>
			</div>
		</>
	);
};
