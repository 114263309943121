import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./DateButton.module.scss";
import { Calendar } from "@streets-heaver/shui2";
import { ScreenSize, useContentSizeClass } from "@streetsheaver/compucore";
import { faChevronDown } from "@shfortawesome/pro-regular-svg-icons";
import { useState, useRef } from "react";
import { useOutsideAlerter } from "../../utils/hooks/useOutsideAlerter";
import { longDateFormat, shortDateFormat } from "../../utils/dateFormatting";
import { clsx } from "clsx";
import { Button } from "@streets-heaver/core";

export const DateButton = ({ enabled, calendarProps }) => {
	const width = useContentSizeClass();
	const [open, setOpen] = useState(false);
	const parentRef = useRef();

	useOutsideAlerter(parentRef, () => setOpen(false));

	function handleSelected(e) {
		calendarProps.onSelected && calendarProps.onSelected(e);
		setOpen(false);
	}

	const date =
		width < ScreenSize.TabletPortrait ? shortDateFormat(calendarProps.date) : longDateFormat(calendarProps.date);

	return (
		<div className={classes.dateButton} ref={parentRef}>
			<Button
				className={clsx(classes.button, enabled && classes.enabled)}
				data-testid="date-button"
				selected={open}
				appearance="subtle"
				onClick={() => setOpen((prev) => !prev)}
			>
				<div className={classes.buttonContent}>
					<p>{date}</p>
					<FontAwesomeIcon icon={faChevronDown} />
				</div>
			</Button>
			<div className={classes.dateText}>{date}</div>
			<div className={clsx(classes.calendar, !enabled && classes.hidden)}>
				<Calendar visible={open} {...calendarProps} onSelected={handleSelected} automationId="date-button-calendar" />
			</div>
		</div>
	);
};
