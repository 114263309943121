import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { localFromUTC, urlDateTimeFormat } from "../../utils";
import { widgetRefetchInterval } from "../../globals/constants";
import { useMsal } from "@azure/msal-react";

export const useClinicianAvailability = (date, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const results = useQuery({
		queryKey: ["clinicianAvailability", currentClinician, date],
		queryFn: async () => {
			const data = await makeApiRequest(
				instance,
				`${
					currentDataSource?.DataSourceId
				}/clinician/${currentClinician}/bookings/availability?date=${urlDateTimeFormat(date)}`,
			);
			if (data?.request?.response) throw new Error("Getting clinician availability Failed");
			const processedAvailability = [];
			data?.availabilities.map((availability) => {
				const availabilityStart = localFromUTC(availability?.start);
				const availabilityEnd = localFromUTC(availability?.end);
				processedAvailability.push({ ...availability, start: availabilityStart, end: availabilityEnd });
			});
			return { ...data, availabilities: processedAvailability };
		},
		refetchInterval: widgetRefetchInterval,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!date && enabled,
	});

	return results;
};
