import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";

export const useEditAppointment = () => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const navigate = useNavigate();
	const { instance } = useMsal();

	return useMutation({
		mutationKey: ["editAppointment"],
		mutationFn: (data) => {
			return makeApiRequest(
				instance,
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/bookings/edit`,
				{
					method: "put",
					body: data,
				},
			);
		},
		onError: (e) => {
			toast.error({
				title: "Error editing appointment",
				subtitle: e?.response?.data?.detail ?? `Failed editing appointment. Please try again later.`,
			});
		},
		onSuccess: () => {
			navigate(-1);
		},
	});
};
