import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useContext } from "react";
import { ClinicianContext } from "../../providers/ClinicianProvider";
import { useDataSources } from "@streetsheaver/compucore";
import { useMsal } from "@azure/msal-react";

export const useClinicians = () => {
	const { currentDataSource } = useDataSources();
	const { instance } = useMsal();

	const query = useQuery({
		queryKey: ["availableClinicians", currentDataSource],
		queryFn: async () => {
			return await makeApiRequest(instance, `${currentDataSource?.DataSourceId}/setup`, {}, "v2");
		},
		enabled: !!currentDataSource,
		refetchOnWindowFocus: false,
	});

	return query;
};

export const useCurrentClinicians = () => useContext(ClinicianContext);
