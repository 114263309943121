import { makeApiRequestV2 } from "../makeApiRequest";

export const deleteUpload = async (instance, { dataSourceId, patientId, fileId }) => {
	return await makeApiRequestV2(
		instance,
		`/datasource/${dataSourceId}/patient/${patientId}/attachment/file/${fileId}/v1`,
		{
			method: "delete",
		},
	);
};
