import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { useVersionGate } from "@streets-heaver/core";
import { FEATURE_FLAGS } from "../../../globals/featureFlags";
import { useMsal } from "@azure/msal-react";

export const useReportTemplatesLookup = (type, searchText) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const isV2 = useVersionGate(FEATURE_FLAGS.REPORT_TEMPLATE_TYPE);

	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "report-templates", type, searchText],
		queryFn: async ({ pageParam }) => {
			const data = isV2
				? await getReportTemplatesV2(
						instance,
						currentDataSource?.DataSourceId,
						currentClinician,
						pageParam,
						12,
						type,
						searchText,
					)
				: await getReportTemplatesV1(
						instance,
						currentDataSource?.DataSourceId,
						currentClinician,
						pageParam,
						12,
						type,
						searchText,
					);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
	});

	return {
		...query,
		data: query?.data?.pages?.flatMap((page) => page?.data),
	};
};

const getReportTemplatesV2 = async (
	instance,
	dataSourceId,
	clinicianId,
	skip = 0,
	take = 12,
	type = "clinic",
	searchText = "",
) => {
	const data = await makeApiRequest(
		instance,
		`${dataSourceId}/clinician/${clinicianId}/lookup/reporttemplates?reportType=${type}&skip=${skip}&take=${take + 1}${
			searchText !== "" ? `&searchtext=${encodeURIComponent(searchText)}` : ""
		}`,
		{},
		"v2",
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};

const getReportTemplatesV1 = async (
	instance,
	dataSourceId,
	clinicianId,
	skip = 0,
	take = 12,
	type = "clinic",
	searchText = "",
) => {
	const data = await makeApiRequest(
		instance,
		`${dataSourceId}/clinician/${clinicianId}/lookup/reporttemplates?isSurgicalList=${
			type === "surgical"
		}&skip=${skip}&take=${take + 1}${searchText !== "" ? `&searchtext=${encodeURIComponent(searchText)}` : ""}`,
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
