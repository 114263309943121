import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useFilterList = (initialFilters, validationFunction = (value) => value) => {
	const navigate = useNavigate();

	const filtersFromURL = useMemo(() => {
		const filtersClone = { ...initialFilters };
		const searchParams = new URLSearchParams(window.location.search);

		Object.keys(filtersClone).forEach((filterName) => {
			const filter = filtersClone[filterName];
			let filterFromURL = searchParams.getAll(filterName);
			filterFromURL = filterFromURL.map((value) => {
				if (value === "false") return false;
				if (value === "true") return true;
				return value;
			});
			if (filterFromURL.length) filtersClone[filterName] = filter.cloneWithNewValue(filterFromURL);
		});

		return validationFunction(filtersClone, false);
	}, [initialFilters, validationFunction]);

	const [appliedFilters, setAppliedFilters] = useState(filtersFromURL);

	const updateAppliedFilters = (filters, showToast) => {
		typeof filters === "function"
			? setAppliedFilters((prev) => validationFunction(filters(prev, showToast)))
			: setAppliedFilters(validationFunction(filters, showToast));
	};

	useEffect(() => {
		const params = new URLSearchParams();
		for (const [filterName, filter] of Object.entries(appliedFilters)) {
			filter.value.forEach((value) => params.append(filterName, value));
		}

		navigate({ search: params.toString() }, { replace: true });
	}, [appliedFilters, navigate]);

	return {
		appliedFilters,
		updateAppliedFilters,
		appliedFiltersCount: Object.values(appliedFilters).filter((f) => !f.isDefault()).length || undefined,
	};
};
