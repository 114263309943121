import { Datebox, Field } from "@streets-heaver/shui2";
import { Switch } from "@streets-heaver/shui2/inputs";
import { useEffect, useState } from "react";
import { FiltersDialog } from "../FiltersDialog";
import dayjs from "dayjs/esm";

export const OrdersFilterDialog = ({ appliedFilters, setAppliedFilters, defaultFilters, visible, setVisible }) => {
	const [hideInProgressInput, setHideInProgressInput] = useState(appliedFilters?.hideInProgress?.value[0]);
	const [hideSeenInput, setHideSeenInput] = useState(appliedFilters?.hideSeen?.value[0]);
	const [dateFromInput, setDateFromInput] = useState(new Date().toISOString());

	const appliedDateFrom = appliedFilters?.dateFrom?.value[0];

	useEffect(() => {
		setHideInProgressInput(appliedFilters?.hideInProgress?.value[0] || false);
		setHideSeenInput(appliedFilters?.hideSeen?.value[0] || false);
		setDateFromInput(appliedDateFrom);
	}, [visible, appliedFilters, appliedDateFrom]);

	function getSelectedFilters() {
		const selectedFilters = {};

		selectedFilters.hideInProgress = appliedFilters.hideInProgress.cloneWithNewValue([hideInProgressInput]);
		selectedFilters.hideSeen = appliedFilters.hideSeen.cloneWithNewValue([hideSeenInput]);
		selectedFilters.dateFrom = appliedFilters.dateFrom.cloneWithNewValue(
			dateFromInput ? [dayjs(dateFromInput).startOf("day").format()] : [],
		);

		return selectedFilters;
	}

	return (
		<FiltersDialog
			defaultFilters={defaultFilters}
			getSelectedFilters={getSelectedFilters}
			setAppliedFilters={setAppliedFilters}
			setVisible={setVisible}
			visible={visible}
		>
			<Field label="Hide in progress">
				<Switch
					label={hideInProgressInput ? "On" : "Off"}
					checked={hideInProgressInput}
					onChange={setHideInProgressInput}
					automationId="in-progress-filter-input"
				/>
			</Field>
			<Field label={"Hide seen"}>
				<Switch
					label={hideSeenInput ? "On" : "Off"}
					checked={hideSeenInput}
					onChange={setHideSeenInput}
					automationId="seen-filter-input"
				/>
			</Field>
			<Field label="Date from (to today)">
				<Datebox
					type="filledDarker"
					key={`fromDatebox-${appliedDateFrom}`}
					customWidth={150}
					onSelected={(date) => date && setDateFromInput(date.toISOString())}
					size="large"
					showInitialDate={!!appliedDateFrom}
					defaultDate={appliedDateFrom && new Date(appliedDateFrom)}
					automationId="from-filter-input"
				/>
			</Field>
		</FiltersDialog>
	);
};
