import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";
import toast from "react-hot-toast";
import { useMsal } from "@azure/msal-react";

export const usePrintList = (type) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	return useMutation({
		mutationKey: ["printList"],
		mutationFn: (data) => {
			return makeApiRequest(
				instance,
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/print/${type}`,
				{
					method: "post",
					body: data,
				},
			);
		},
		onError: (e) => {
			toast.error({
				title: "Error queuing report",
				subtitle: e?.response?.data?.detail ?? `Failed queuing report. Please try again later.`,
			});
		},
	});
};
