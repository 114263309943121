import { makeApiRequestV2 } from "../makeApiRequest";

export const getJournalEntries = async (instance, { dataSourceId, patientId, skip = 0, take = 50, filter, sort }) => {
	const data = await makeApiRequestV2(
		instance,
		`/datasource/${dataSourceId}/journal/entries/v1?patientId=${patientId}&skip=${skip}&take=${
			take + 1
		}&searchType=${filter}&orderby=${sort}`,
	);
	return data;
};
