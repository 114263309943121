import { Link } from "react-router-dom";
import { ageFromDate, argbToRgba, formatNameAsInitials } from "../../utils";
import { formatNameAsReversed, shortDateFormat } from "@streetsheaver/compucore";
import classes from "./globalSearchPatient.module.scss";
import { usePatientImage } from "../../api/hooks";
import { Persona } from "@streets-heaver/shui2";

export const GlobalSearchPatientGhost = () => {
	return (
		<div className={classes.patientResult}>
			<Persona
				icon={{
					type: "avatar",
					props: {
						ghost: true,
					},
				}}
				ghost
			/>
		</div>
	);
};

export const GlobalSearchPatient = ({ data, closeSearchPanel }) => {
	const { data: patientImage } = usePatientImage(data.hasImage, data.patientId, true);

	return (
		<Link to={`./patient-area/${data.patientId}`} onClick={closeSearchPanel} className={classes.patientResult}>
			<Persona
				icon={{
					type: "avatar",
					props: {
						initials: formatNameAsInitials(data.forename, data.surname),
						image: patientImage,
						badgeProps: {
							solidColour: argbToRgba(data.genderColour),
							size: 10,
						},
					},
				}}
				primaryText={formatNameAsReversed(data.forename, data.surname, data.title)}
				number={{
					text: data.patientPrimaryId,
					link: `./patient-area/${data.patientId}`,
					canCopy: true,
				}}
				secondaryText={data.dateOfBirth && `${shortDateFormat(data.dateOfBirth)} (${ageFromDate(data.dateOfBirth)})`}
				tertiaryText={data.addressLine1}
				customWidth="100%"
			/>
		</Link>
	);
};
