import { usePatientImages, usePatientsLookup, useRecentPatients } from "../../../api/hooks";
import { argbToRgba, useFormError } from "../../../utils";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../FormField/FormField";
import { PatientLookup } from "@streetsheaver/compucore";
import {
	patientLookupInvalidSchemaError,
	patientLookupSchemaError,
	patientLookupHelperText,
	patientLookupNoRecentsText,
	patientLookupNoPatientsText,
} from "../../../globals/messages";
import { useVersionGate } from "@streets-heaver/core";
import { FEATURE_FLAGS } from "../../../globals/featureFlags";

export const PatientsLookup = ({ label = "Patient", name = "patient", isMandatory, automationId }) => {
	const { control } = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");

	const hasRecentPatients = useVersionGate(FEATURE_FLAGS.RECENT_PATIENTS_LOOKUP);

	const recentPatientQuery = useRecentPatients(searchTerm === "" && hasRecentPatients);
	const patientQuery = usePatientsLookup(searchTerm, searchTerm !== "" || !hasRecentPatients);

	const currentPatientQuery = searchTerm !== "" || !hasRecentPatients ? patientQuery : recentPatientQuery;

	const patientThumbnailsQuery = usePatientImages(
		currentPatientQuery?.data?.map((patient) => ({
			patientId: patient.patientId,
			hasImage: patient.hasImage,
		})) ?? [],
		true,
	);
	const patientData = currentPatientQuery?.data?.map((patient, index) => ({
		...patient,
		image: patientThumbnailsQuery[index].data,
		genderColour: argbToRgba(patient.genderColour),
	}));

	const error = useFormError(name);

	return (
		<FormField
			label={label}
			name={name}
			isMandatory={isMandatory}
			helperText={hasRecentPatients && patientLookupHelperText}
		>
			<Controller
				name={name}
				control={control}
				rules={{
					validate: {
						required: (value) => value || patientLookupSchemaError,
						allowableBookings: (value) =>
							(value && value?.canMakeBooking) || (value && patientLookupInvalidSchemaError),
					},
				}}
				render={({ field: { value, onChange, ref } }) => (
					<PatientLookup
						selected={value}
						patientLinkPrefix={"../patient-area/"}
						onSearchTermChange={setSearchTerm}
						setSelected={onChange}
						searchQueryData={{
							...currentPatientQuery,
							data: patientData ?? [],
							emptyDataMessage: searchTerm === "" ? patientLookupNoRecentsText : patientLookupNoPatientsText,
						}}
						reference={ref}
						isError={error?.length > 0}
						automationId={`${automationId}-patient`}
					/>
				)}
			/>
		</FormField>
	);
};
