import { makeApiRequestV2 } from "../makeApiRequest";

export const postBeginUpload = async (instance, { dataSourceId, patientId, file }) => {
	const data = await makeApiRequestV2(
		instance,
		`/datasource/${dataSourceId}/patient/${patientId}/attachment/beginupload/v1`,
		{
			method: "post",
			body: { fileName: file.name },
		},
	);
	return data?.fileUploadId;
};
