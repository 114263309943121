import { useQueries } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { convertBlobToImage } from "../../utils";
import { useMsal } from "@azure/msal-react";
import { useVersionGate } from "@streets-heaver/core";
import { FEATURE_FLAGS } from "../../globals/featureFlags";

export const usePatientImages = (patients, isThumbnail = false) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();
	const isCorrectVersion = useVersionGate(FEATURE_FLAGS.PATIENT_IMAGES);

	const query = useQueries({
		queries: patients.map((patient) => ({
			queryFn: async () => {
				const data = await makeApiRequest(
					instance,
					`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/patient/${patient.patientId}/image?thumbnail=${isThumbnail}`,
				);
				if (data?.request?.response) throw new Error(`Getting patient image for patient ${patient.patientId} Failed`);
				return data;
			},
			queryKey: [currentDataSource?.DataSourceId, "patient", patient, "thumbnail"],
			select: convertBlobToImage,
			retry: false,
			enabled: !!patient.hasImage && isCorrectVersion,
		})),
	});

	return query;
};
