import classes from "./PatientView.module.scss";
import { Outlet, useLocation, useParams } from "react-router";
import { ScreenSize, useScreenSizeClass } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import {
	AbnormalResults,
	OrderStatus,
	PatientNotes,
	PatientOrders,
	UnseenReports,
	NextAppointment,
	NoAccessPage,
	WidgetGrid,
	ClinicianPatientBanner,
} from "../../components";
import { usePatientBanner } from "../../api/hooks/usePatientBanner";
import { formatNameAsReversed } from "../../utils";
import { ErrorDisplay } from "@streets-heaver/shui2";
import { patientIdInvalidError } from "../../globals/messages";
import { Card } from "@streets-heaver/core";

export default function PatientView() {
	const { userSecurityFlags } = useCurrentClinicians();
	const { patientId } = useParams();
	const patientBannerQuery = usePatientBanner(patientId);
	const patientData = patientBannerQuery?.data;
	const width = useScreenSizeClass();
	const location = useLocation();
	const pathname = location.pathname;
	const routes = pathname.split("/");

	if (patientBannerQuery.isError) {
		return (
			<div className={classes.patientError}>
				<Card className={classes.patientErrorCard}>
					<ErrorDisplay
						title="Patient not found"
						primaryButtonProps={{
							children: width < ScreenSize.TabletPortrait ? "Return Home" : "Take me to the home page",
							navigation: { to: `../${routes[0]}` },
							mobile: width < ScreenSize.TabletPortrait,
						}}
					>
						{patientIdInvalidError}
					</ErrorDisplay>
				</Card>
			</div>
		);
	}

	return (
		<div className={classes.PatientView} data-testid={"pagePatientView"}>
			{!userSecurityFlags.includes("patientview") ? (
				<NoAccessPage />
			) : (
				<div className={classes.Body}>
					<Outlet />
					<ClinicianPatientBanner patientData={patientBannerQuery?.data} isLoading={patientBannerQuery.isLoading} />
					<WidgetGrid align={width < ScreenSize.TabletLandscape ? "centre" : "left"}>
						<WidgetLayout width={width} data={patientData} userSecurityFlags={userSecurityFlags} />
					</WidgetGrid>
				</div>
			)}
		</div>
	);
}

const WidgetLayout = ({ width, data, userSecurityFlags }) => {
	const patientName = formatNameAsReversed(data?.forename, data?.surname, data?.title);

	if (width >= ScreenSize.Small)
		return (
			<>
				<WidgetGrid.Item positionX={1} positionY={1} width={2} height={4}>
					<NextAppointment patientId={data?.patientId} />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={1} width={2} height={2}>
					<AbnormalResults
						hasPermission={userSecurityFlags.includes("patientview-abnormalresults")}
						patientId={data?.patientId}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={5} positionY={1} width={2} height={2}>
					<OrderStatus
						patientId={data?.patientId}
						hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={7} positionY={1} width={1} height={1}>
					<UnseenReports
						patientId={data?.patientId}
						hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={7} positionY={2} width={1} height={1}>
					<PatientNotes />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={3} width={4} height={2}>
					<PatientOrders
						hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
						patientName={patientName}
					/>
				</WidgetGrid.Item>
			</>
		);

	if (width >= ScreenSize.TabletLandscape)
		return (
			<>
				<WidgetGrid.Item positionX={1} positionY={1} width={2} height={2}>
					<NextAppointment patientId={data?.patientId} />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={1} width={2} height={2}>
					<AbnormalResults
						hasPermission={userSecurityFlags.includes("patientview-abnormalresults")}
						patientId={data?.patientId}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={5} positionY={1} width={1} height={1}>
					<UnseenReports
						patientId={data?.patientId}
						hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={5} positionY={2} width={1} height={1}>
					<PatientNotes />
				</WidgetGrid.Item>
			</>
		);

	if (width >= ScreenSize.TabletPortrait)
		return (
			<>
				<WidgetGrid.Item positionX={1} positionY={1} width={2} height={2}>
					<NextAppointment patientId={data?.patientId} />
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={1} width={2} height={2}>
					<AbnormalResults
						hasPermission={userSecurityFlags.includes("patientview-abnormalresults")}
						patientId={data?.patientId}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={1} positionY={3} width={2} height={2}>
					<OrderStatus
						patientId={data?.patientId}
						hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={3} positionY={3} width={1} height={1}>
					<UnseenReports
						patientId={data?.patientId}
						hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
					/>
				</WidgetGrid.Item>
				<WidgetGrid.Item positionX={4} positionY={3} width={1} height={1}>
					<PatientNotes />
				</WidgetGrid.Item>
			</>
		);

	return (
		<>
			<WidgetGrid.Item positionX={1} positionY={1} width={1} height={1}>
				<UnseenReports
					patientId={data?.patientId}
					hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
				/>
			</WidgetGrid.Item>
			<WidgetGrid.Item positionX={2} positionY={1} width={1} height={1}>
				<PatientNotes />
			</WidgetGrid.Item>
		</>
	);
};
