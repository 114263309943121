import { useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { widgetRefetchInterval } from "../../../globals/constants";
import { useMsal } from "@azure/msal-react";
import { useVersionGate } from "@streets-heaver/core";
import { FEATURE_FLAGS } from "../../../globals/featureFlags";

export const useAbnormalResults = (patientId, take) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const hasReferenceRange = useVersionGate(FEATURE_FLAGS.RESULTS_REFERENCE_RANGE);
	const { instance } = useMsal();

	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["abnormalResults", currentClinician, patientId, take],
		queryFn: async ({ pageParam }) => {
			const data = await getAbnormalResults(
				instance,
				currentDataSource?.DataSourceId,
				currentClinician,
				patientId,
				hasReferenceRange ? "v3" : "v2",
				pageParam,
				take,
			);
			if (data?.request?.response) throw new Error("Getting abnormal results for patient failed");
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchInterval: widgetRefetchInterval,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId && !isNaN(take),
	});

	const flatData = useMemo(() => query.data?.pages?.flatMap((page) => page?.data), [query?.data]);

	return { ...query, data: flatData };
};

const getAbnormalResults = async (instance, dataSourceId, clinicianId, patientId, version, skip = 0, take = 12) => {
	const data = await makeApiRequest(
		instance,
		`${dataSourceId}/clinician/${clinicianId}/patient/${patientId}/widget/abnormalresults?skip=${skip}&take=${
			take + 1
		}`,
		{},
		version,
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
