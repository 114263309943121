import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { urlDateTimeFormat } from "../../utils";
import { widgetRefetchInterval } from "../../globals/constants";
import { useMsal } from "@azure/msal-react";

export const useSchedulerEvents = (dates, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const results = useQuery({
		queryKey: ["schedulerEvents", currentClinician, dates],
		queryFn: async () => {
			const data = await makeApiRequest(
				instance,
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/scheduler/events?${dates
					.map((date) => `dates=${urlDateTimeFormat(date)}`)
					.join("&")}`,
				{},
				"v2",
			);
			if (data?.request?.response) throw new Error("Getting scheduler events Failed");

			return data;
		},
		refetchInterval: widgetRefetchInterval,
		enabled: !!currentDataSource && !!currentClinician && !!dates && enabled,
	});

	return results;
};
