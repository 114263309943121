import classes from "./FiltersDialog.module.scss";
import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@streets-heaver/core";

export const FiltersDialog = ({
	visible,
	setVisible,
	setAppliedFilters,
	defaultFilters,
	getSelectedFilters,
	disableAction,
	children,
}) => {
	return (
		<Dialog visible={visible} setVisible={setVisible} data-testid="filters-dialog">
			<DialogHeader>Filters</DialogHeader>
			<DialogBody>
				<div className={classes.popupSection}>{children}</div>
			</DialogBody>
			<DialogFooter
				primaryButton={{
					children: "Apply filters",
					disabled: disableAction,
					onClick: () => {
						setAppliedFilters(getSelectedFilters());
						setVisible(false);
					},
				}}
				secondaryButton={{ children: "Cancel", onClick: () => setVisible(false) }}
				tertiaryButton={{
					children: "Clear all filters",
					className: classes.redButton,
					onClick: () => {
						setAppliedFilters(defaultFilters, false);
					},
				}}
			/>
		</Dialog>
	);
};
